import { Layout } from "components";
import { EMAIL_ADDRESS } from "utils/constants";
import logoImage from "static/logo.png";

const infoSet = [
  { name: "会社名", value: "ラピュタテクノロジーズ株式会社" },
  { name: "設立", value: "2017年7月21日" },
  { name: "代表", value: "代表取締役　市川　賢仁" },
  { name: "住所", value: "210-0024　神奈川県川崎市川崎区日進町" },
  {
    name: "事業内容",
    value:
      "ペットに関わるインターネットサービスの提供ペットビジネスその他のビジネスのセールス及びマーケティングのコンサルティングサービスの提供",
  },
  { name: "お問い合わせ先", value: EMAIL_ADDRESS },
];

const About = () => {
  return (
    <Layout title="Album">
      <div className="max-w-7xl mx-auto px-10 md:px-56 py-28 flex flex-col justify-center border-x-2">
        <img src={logoImage} alt="Wango Logo" className="mb-20" />
        {infoSet.map((info) => (
          <div className="grid grid-cols-3 my-2">
            <div className="font-bold">{info.name}</div>
            <div className="col-span-2">
              {info.value === EMAIL_ADDRESS ? (
                <a
                  href={`mailto:${EMAIL_ADDRESS}`}
                  className="text-yellow-500 hover:text-yellow-400"
                >
                  {EMAIL_ADDRESS}
                </a>
              ) : (
                info.value
              )}
            </div>
          </div>
        ))}
      </div>
    </Layout>
  );
};

export default About;
