import { Link } from "react-router-dom";
import routes from "utils/routes";

const navigation = [
  {
    name: "プライバシーポリシー",
    props: {
      to: routes.privacy,
      target: "_blank",
    },
    icon: (props: any) => (
      <svg fill="currentColor" viewBox="0 0 41.48 56" {...props}>
        <path d="M35,2.5a4,4,0,0,1,4,4v43a4,4,0,0,1-4,4H6.49a4,4,0,0,1-4-4v-43a4,4,0,0,1,4-4H35M35,0H6.49A6.51,6.51,0,0,0,0,6.49v43A6.51,6.51,0,0,0,6.49,56H35a6.51,6.51,0,0,0,6.49-6.49v-43A6.51,6.51,0,0,0,35,0Z"></path>
        <path d="M9.34,29.8v4.72H6.63V21.1H11.2a4.21,4.21,0,0,1,3.18,1.23,4.45,4.45,0,0,1,1.18,3.21,4.15,4.15,0,0,1-1.17,3.11,4.41,4.41,0,0,1-3.24,1.15Zm0-2.26H11.2A1.47,1.47,0,0,0,12.4,27a2.26,2.26,0,0,0,.42-1.47,2.66,2.66,0,0,0-.43-1.6,1.36,1.36,0,0,0-1.16-.6H9.34Z"></path>
        <path d="M17.37,34.52V21.1h3.55a4.9,4.9,0,0,1,3.74,1.49,5.87,5.87,0,0,1,1.43,4.09v2.18A5.89,5.89,0,0,1,24.69,33a5,5,0,0,1-3.85,1.51Zm2.71-11.16v8.91h.81a2.27,2.27,0,0,0,1.91-.72,4.29,4.29,0,0,0,.58-2.46V26.76a4.79,4.79,0,0,0-.53-2.63,2.12,2.12,0,0,0-1.79-.77Z"></path>
        <path d="M35,29H30.74v5.48H28V21.1h7.43v2.26H30.74v3.43H35Z"></path>
      </svg>
    ),
  },
  {
    name: "利用規約",
    props: {
      to: routes.agreement,
      target: "_blank",
    },
    icon: (props: any) => (
      <svg fill="currentColor" viewBox="0 0 41.48 56" {...props}>
        <path d="M35,2.5a4,4,0,0,1,4,4v43a4,4,0,0,1-4,4H6.49a4,4,0,0,1-4-4v-43a4,4,0,0,1,4-4H35M35,0H6.49A6.51,6.51,0,0,0,0,6.49v43A6.51,6.51,0,0,0,6.49,56H35a6.51,6.51,0,0,0,6.49-6.49v-43A6.51,6.51,0,0,0,35,0Z"></path>
        <path d="M9.34,29.8v4.72H6.63V21.1H11.2a4.21,4.21,0,0,1,3.18,1.23,4.45,4.45,0,0,1,1.18,3.21,4.15,4.15,0,0,1-1.17,3.11,4.41,4.41,0,0,1-3.24,1.15Zm0-2.26H11.2A1.47,1.47,0,0,0,12.4,27a2.26,2.26,0,0,0,.42-1.47,2.66,2.66,0,0,0-.43-1.6,1.36,1.36,0,0,0-1.16-.6H9.34Z"></path>
        <path d="M17.37,34.52V21.1h3.55a4.9,4.9,0,0,1,3.74,1.49,5.87,5.87,0,0,1,1.43,4.09v2.18A5.89,5.89,0,0,1,24.69,33a5,5,0,0,1-3.85,1.51Zm2.71-11.16v8.91h.81a2.27,2.27,0,0,0,1.91-.72,4.29,4.29,0,0,0,.58-2.46V26.76a4.79,4.79,0,0,0-.53-2.63,2.12,2.12,0,0,0-1.79-.77Z"></path>
        <path d="M35,29H30.74v5.48H28V21.1h7.43v2.26H30.74v3.43H35Z"></path>
      </svg>
    ),
  },
  {
    name: "会社情報",
    props: {
      to: routes.about,
    },
  },
];

const Footer = () => {
  return (
    <footer className="flex-none">
      <div className="max-w-7xl mx-auto py-8 px-4 sm:px-6 md:flex md:items-center md:justify-between lg:px-16 bg-green-800 ">
        <div className="flex flex-col gap-y-3 md:flex-row items-center justify-center gap-x-8">
          {navigation.map((item) => (
            <Link
              key={item.name}
              className="text-gray-200 hover:text-gray-300 flex"
              {...item.props}
            >
              <span>{item.name}</span>
              {item.icon ? (
                <item.icon className="ml-1 h-6 w-6" aria-hidden="true" />
              ) : null}
            </Link>
          ))}
        </div>
        <div className="mt-8 md:mt-0">
          <p className="text-center text-base text-gray-200">
            Copyright &copy; {new Date().getFullYear()} Raputa-technologies Inc.
            All rights reserved.
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
