import { Pagination } from "@mui/material";

interface Props {
  count: number;
  page: number;
  onChange: (_: any, value: number) => void;
}

const MyPagination = ({ count, page, onChange }: Props) => {
  return count ? (
    <Pagination
      className="flex justify-center mt-10"
      count={count}
      page={page}
      onChange={onChange}
    />
  ) : null;
};

export default MyPagination;
