import { Layout } from "components";
import { createUserWithEmailAndPassword } from "firebase/auth";
import { addDoc, collection } from "firebase/firestore";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { useAuth, useFirestore } from "reactfire";
import { COLLECTION_SET } from "utils/constants";
import routes from "utils/routes";

type FormData = {
  nickname: string;
  email: string;
  password: string;
  password2: string;
};

export default function SignUp() {
  const navigate = useNavigate();
  const auth = useAuth();
  const firestore = useFirestore();

  const signUp = async ({ email, password }: FormData) => {
    await createUserWithEmailAndPassword(auth, email, password);
    await addDoc(collection(firestore, COLLECTION_SET.USERS), {
      area: "",
      avatar: "",
      birth: "",
      email,
      familyName: "",
      familyNameFurigana: "",
      favourites: [],
      followers: [],
      gender: "",
      givenName: "",
      givenNameFurigana: "",
      nickname: "",
      pets: [],
      photos: [],
      prefecture: "",
    });
    navigate(routes.home);
  };

  const { register, handleSubmit } = useForm<FormData>();
  const onSubmit = handleSubmit((data) => signUp(data));

  return (
    <Layout title="Sign Up">
      <div className="min-h-screen max-w-7xl mx-auto border-x-2 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <h2 className="mt-6 text-center text-4xl font-bold text-gray-600">
            Sign up with Email
          </h2>
          <p className="mt-2 text-center text-sm text-gray-600">
            Already a member?{" "}
            <Link
              to={routes.signin}
              className="font-medium text-yellow-500 hover:text-yellow-400"
            >
              Sign in
            </Link>
          </p>
        </div>

        <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
          <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
            <form className="space-y-6" onSubmit={onSubmit}>
              <div>
                <label
                  htmlFor="nickname"
                  className="block text-sm font-medium text-gray-700"
                >
                  Nickname
                </label>
                <div className="mt-1">
                  <input
                    id="nickname"
                    type="text"
                    autoComplete="nickname"
                    required
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-green-600 focus:border-green-600 sm:text-sm"
                    {...register("nickname")}
                  />
                </div>
              </div>

              <div>
                <label
                  htmlFor="email"
                  className="block text-sm font-medium text-gray-700"
                >
                  Email address
                </label>
                <div className="mt-1">
                  <input
                    id="email"
                    type="email"
                    autoComplete="email"
                    required
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-green-600 focus:border-green-600 sm:text-sm"
                    {...register("email")}
                  />
                </div>
              </div>

              <div>
                <label
                  htmlFor="password"
                  className="block text-sm font-medium text-gray-700"
                >
                  Password
                </label>
                <div className="mt-1">
                  <input
                    id="password"
                    type="password"
                    autoComplete="new-password"
                    required
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-green-600 focus:border-green-600 sm:text-sm"
                    {...register("password")}
                  />
                </div>
              </div>

              <div>
                <label
                  htmlFor="password2"
                  className="block text-sm font-medium text-gray-700"
                >
                  Re-enter the Password
                </label>
                <div className="mt-1">
                  <input
                    id="password2"
                    type="password"
                    required
                    autoComplete="new-password"
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-green-600 focus:border-green-600 sm:text-sm"
                    {...register("password2")}
                  />
                </div>
                <p className="mt-2 block text-sm text-gray-500">
                  Please provide a password with at least 6 characters.
                </p>
              </div>

              <div>
                <button
                  type="submit"
                  className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-green-700 hover:bg-green-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-600"
                >
                  Sign up
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </Layout>
  );
}
