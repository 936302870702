import { Dialog, Transition } from "@headlessui/react";
import { PlusIcon } from "@heroicons/react/solid";
import { Ad, Layout, ProfileHeader } from "components";
import { useProfileContext, ProfileContext } from "components/ProfileLayout";
import { addDoc, collection } from "firebase/firestore";
import { ref, uploadString } from "firebase/storage";
import { useDataContext } from "providers";
import { Fragment, useState } from "react";
import Cropper from "react-cropper";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { useFirestore, useStorage, useUser } from "reactfire";
import {
  AGE_SET,
  COLLECTION_SET,
  DOG_CATEGORY,
  IMAGE_TYPE,
  Menu,
  PURPOSE_SET,
  UPLOAD_TYPE,
} from "utils/constants";
import routes, { generateRoute } from "utils/routes";

interface FormData {
  title: string;
  category: string;
  age: string[];
  purpose: string[];
  galleries: any;
  description: string;
  ingredients: any;
  knack: string;
  steps: any;
  [key: string]: any;
}

const AuthPost = () => {
  const storage = useStorage();
  const navigate = useNavigate();
  const { id } = useProfileContext();

  const [state, setState] = useState<FormData>({
    title: "",
    category: "",
    age: [],
    purpose: [],
    galleries: {},
    description: "",
    ingredients: {},
    knack: "",
    steps: [],
  });

  // Galleries
  const [modal, setModal] = useState(false);
  const [image, setImage] = useState("");
  const [fileName, setFilename] = useState("");
  const [cropper, setCropper] = useState<any>();
  const [fieldName, setFieldName] = useState("");
  const [stepIndex, setStepIndex] = useState(0);
  const onChange = (name?: string, index?: number) => (e: any) => {
    e.preventDefault();
    let files;
    if (e.dataTransfer) {
      files = e.dataTransfer.files;
    } else if (e.target) {
      files = e.target.files;
    }
    const reader = new FileReader();
    reader.onload = () => {
      setImage(reader.result as any);
    };
    reader.readAsDataURL(files[0]);
    setFilename(files[0].name);
    setModal(true);

    if (name) {
      setFieldName(name);
    }

    if (index) {
      setStepIndex(index);
    }
  };
  const handleGallery = () => {
    if (typeof cropper !== "undefined") {
      const imageUrl = cropper.getCroppedCanvas().toDataURL();
      if (fieldName === "step") {
        const { steps } = state;
        const currentValue = steps.find((i: any) => i.order === stepIndex);
        setState({
          ...state,
          steps: currentValue
            ? steps.map((i: any) => {
                if (i.order === stepIndex) {
                  return { ...i, image: imageUrl, fileName };
                }
                return i;
              })
            : [...steps, { order: stepIndex, image: imageUrl, fileName }],
        });
        setFieldName("");
      } else {
        setState({
          ...state,
          galleries: {
            ...state.galleries,
            [fileName]: imageUrl,
          },
        });
      }
    }
    setModal(false);
  };
  const handleDeleteGallery = (keyName: string) => () => {
    const currentValue = { ...state.galleries };
    delete currentValue[keyName];
    setState({ ...state, galleries: currentValue });
  };

  // Ingredients
  const [count, setCount] = useState(0);
  const handleCount = () => {
    setCount(count + 1);
  };

  // Steps
  const [stepCount, setStepCount] = useState(1);
  const handleStepCount = (add: boolean) => () => {
    setStepCount(add ? stepCount + 1 : stepCount - 1);
  };

  // Others
  const handleSimpleChange = (name: string) => (e: any) => {
    setState({
      ...state,
      [name]: e.target.value,
    });
  };

  const handleCheckbox = (name: string) => (e: any) => {
    const { value } = e.target;
    const currentValue = state[name] as string[];
    const checked = currentValue.includes(value);
    setState({
      ...state,
      [name]: checked
        ? currentValue.filter((i) => i !== value)
        : [...currentValue, value],
    });
  };

  const handleMultiChange = (index: number) => (e: any) => {
    setState({
      ...state,
      ingredients: {
        ...state.ingredients,
        [index]: e.target.value,
      },
    });
  };

  const handleStepInput = (order: number) => (e: any) => {
    const { steps } = state;
    const currentValue = steps.find((i: any) => i.order === order);
    setState({
      ...state,
      steps: currentValue
        ? steps.map((i: any) => {
            if (i.order === order) {
              return { ...i, description: e.target.value };
            }
            return i;
          })
        : [...steps, { order, description: e.target.value }],
    });
  };

  const firestore = useFirestore();
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    setLoading(true);
    const timestamp = Date.now();
    const newData = {
      ...state,
      timestamp,
      age: state.age.join(","),
      authorId: id,
      galleries: Object.keys(state.galleries).map(
        (i) => `${IMAGE_TYPE.GALLERIES}/${id}/${state.title}-${timestamp}-${i}`
      ),
      ingredients: Object.keys(state.ingredients).map(
        (i) => state.ingredients[i]
      ),
      purpose: state.purpose.join(","),
      steps: state.steps.map((i: any) => ({
        order: i.order,
        description: i.description,
        image: `${IMAGE_TYPE.STEPS}/${id}/${state.title}-${timestamp}-${i.fileName}`,
      })),
    };
    await Promise.all(
      Object.keys(state.galleries).map(async (i: any) => {
        const storageRef = ref(
          storage,
          `${IMAGE_TYPE.GALLERIES}/${id}/${state.title}-${timestamp}-${i}`
        );
        await uploadString(storageRef, state.galleries[i], UPLOAD_TYPE);
      })
    );
    await Promise.all(
      state.steps.map(async (i: any) => {
        const storageRef = ref(
          storage,
          `${IMAGE_TYPE.STEPS}/${id}/${state.title}-${timestamp}-${i.fileName}`
        );
        await uploadString(storageRef, i.image, UPLOAD_TYPE);
      })
    );
    await addDoc(collection(firestore, COLLECTION_SET.RECIPES), newData);
    setLoading(false);
    navigate(generateRoute(id, Menu.MyRecipe));
  };

  return (
    <>
      <form className="p-16 grid grid-cols-4" onSubmit={handleSubmit}>
        <div className="col-span-4 md:col-span-3">
          <h1 className="text-2xl font-bold text-gray-900">レシピ投稿</h1>
          {/* Name */}
          <div className="my-6">
            <label
              htmlFor="title"
              className="block text-md font-medium text-gray-700"
            >
              レシピの名前
            </label>
            <div className="mt-2 w-full md:w-1/2">
              <input
                id="title"
                name="title"
                type="text"
                value={state.title}
                onChange={handleSimpleChange("title")}
                required
                className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-green-600 focus:border-green-600 sm:text-sm"
              />
            </div>
          </div>
          {/* Pet Info */}
          <div className="my-16">
            <div className="my-4">
              <label
                htmlFor="category"
                className="block text-md font-medium text-gray-700"
              >
                犬種
              </label>
              <div className="mt-2 w-full md:w-1/2">
                <select
                  id="category"
                  name="category"
                  required
                  value={state.category}
                  onChange={handleSimpleChange("category")}
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-green-600 focus:border-green-600 sm:text-sm"
                >
                  {DOG_CATEGORY.map(({ value, label }) => (
                    <option key={value} value={value}>
                      {label}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="my-4">
              <label
                htmlFor="age"
                className="block text-md font-medium text-gray-700"
              >
                年齢
              </label>
              <div className="mt-2 grid gird-cols-1 md:grid-cols-5 gap-x-6 gap-y-3">
                {AGE_SET.map(({ label, value }) => (
                  <div
                    key={value}
                    className={`border border-green-700 rounded-lg ${
                      state.age.includes(value) ? "bg-green-700 text-white" : ""
                    }`}
                  >
                    <input
                      type="checkbox"
                      value={value}
                      name="age"
                      id={value}
                      className="hidden"
                      onClick={handleCheckbox("age")}
                    />
                    <label
                      htmlFor={value}
                      className="flex justify-center cursor-pointer p-2"
                    >
                      {label}
                    </label>
                  </div>
                ))}
              </div>
            </div>
            <div className="my-4">
              <label
                htmlFor="purpose"
                className="block text-md font-medium text-gray-700"
              >
                目的
              </label>
              <div className="mt-2 grid gird-cols-1 md:grid-cols-5 gap-x-6 gap-y-3">
                {PURPOSE_SET.map(({ label, value }) => (
                  <div
                    key={value}
                    className={`border border-green-700 rounded-lg ${
                      state.purpose.includes(value)
                        ? "bg-green-700 text-white"
                        : ""
                    }`}
                  >
                    <input
                      type="checkbox"
                      value={value}
                      name="purpose"
                      id={value}
                      className="hidden"
                      onClick={handleCheckbox("purpose")}
                    />
                    <label
                      htmlFor={value}
                      className="flex justify-center cursor-pointer p-2"
                    >
                      {label}
                    </label>
                  </div>
                ))}
              </div>
            </div>
          </div>
          {/* Images */}
          <div className="my-16">
            <div className="my-4">
              <h2 className="block text-md font-medium text-gray-700">
                レシピの完成写真
              </h2>
              <div className="mt-3 grid grid-cols-1 md:grid-cols-4 gap-4">
                {Object.keys(state.galleries).length < 4 ? (
                  <div className="border-2 border-dotted">
                    <input
                      type="file"
                      id="galleries"
                      accept="image/*"
                      className="hidden"
                      onChange={onChange()}
                    />
                    <label
                      htmlFor="galleries"
                      className="flex justify-center items-center h-52 cursor-pointer"
                    >
                      <PlusIcon
                        className="block h-8 w-8 text-gray-400"
                        aria-hidden="true"
                      />
                    </label>
                  </div>
                ) : null}
                {Object.keys(state.galleries).map((i) => (
                  <div className="flex flex-col h-52" key={i}>
                    <img
                      src={state.galleries[i]}
                      alt="Gallery"
                      className="h-48"
                    />
                    <button
                      className="font-medium text-yellow-500 hover:text-yellow-400"
                      onClick={handleDeleteGallery(i)}
                    >
                      Delete
                    </button>
                  </div>
                ))}
              </div>
            </div>
          </div>
          {/* Description */}
          <div className="my-16">
            <div className="my-4">
              <label
                htmlFor="description"
                className="block text-md font-medium text-gray-700"
              >
                レシピの紹介
              </label>
              <div className="mt-2">
                <textarea
                  id="description"
                  name="description"
                  rows={4}
                  required
                  value={state.description}
                  onChange={handleSimpleChange("description")}
                  placeholder="レシピの紹介"
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-green-600 focus:border-green-600 sm:text-sm"
                />
              </div>
            </div>
          </div>
          {/* Ingredient */}
          <div className="my-16">
            <div className="my-4">
              <label
                htmlFor="ingredients"
                className="block text-md font-medium text-gray-700"
              >
                レシピの材料
              </label>
              <div className="mt-2 grid grid-cols-2 md:grid-cols-4 gap-4">
                {Array(8 + count * 4)
                  .fill(0)
                  .map((_, index) => (
                    <input
                      key={index}
                      id="ingredients"
                      name={`ingredients${index}`}
                      type="text"
                      onChange={handleMultiChange(index)}
                      className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-green-600 focus:border-green-600 sm:text-sm"
                      placeholder="例:塩 10g"
                    />
                  ))}
                {count < 2 ? (
                  <div
                    className="col-span-4 border-2 border-dotted flex justify-center py-1 cursor-pointer"
                    onClick={handleCount}
                  >
                    <PlusIcon
                      className="block h-8 w-8 text-gray-400"
                      aria-hidden="true"
                    />
                  </div>
                ) : null}
              </div>
            </div>
          </div>
          {/* Step */}
          <div className="my-16">
            <div className="my-4">
              <label
                htmlFor="name"
                className="block text-md font-medium text-gray-700"
              >
                調理方法
              </label>
              <div className="mt-2 grid grid-cols-1 md:grid-cols-3 gap-5">
                {Array(stepCount)
                  .fill(0)
                  .map((_, index) => {
                    const { steps } = state;
                    const currentValue = steps.find(
                      (i: any) => i.order === index
                    );

                    return (
                      <Fragment key={index}>
                        <div>
                          <h2>ステップ {index + 1} の写真を追加</h2>
                          {currentValue && currentValue.image ? (
                            <label htmlFor={`step${index}cover`}>
                              <img
                                src={currentValue.image}
                                alt="Steps"
                                className="h-28"
                              />
                              <input
                                type="file"
                                id={`step${index}cover`}
                                accept="image/*"
                                className="hidden"
                                onChange={onChange("step", index)}
                              />
                            </label>
                          ) : (
                            <div className="mt-3 border-2 border-dotted cursor-pointer">
                              <input
                                type="file"
                                id={`step${index}cover`}
                                accept="image/*"
                                className="hidden"
                                onChange={onChange("step", index)}
                              />
                              <label
                                htmlFor={`step${index}cover`}
                                className="flex justify-center items-center h-28 cursor-pointer"
                              >
                                <PlusIcon
                                  className="block h-8 w-8 text-gray-400"
                                  aria-hidden="true"
                                />
                              </label>
                            </div>
                          )}
                        </div>
                        <div className="col-span-2">
                          <h2>ステップ {index + 1} の紹介文を追加</h2>
                          <textarea
                            name={`steps${index}`}
                            rows={4}
                            required
                            placeholder="紹介文"
                            onChange={handleStepInput(index)}
                            className="mt-3 h-28 appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-green-600 focus:border-green-600 sm:text-sm"
                          />
                        </div>
                      </Fragment>
                    );
                  })}
              </div>
              {stepCount < 6 ? (
                <div className="mt-6">
                  <h2>料理ステップをさらに追加</h2>
                  <div
                    className="mt-3 border-2 border-dotted flex justify-center items-center py-1 cursor-pointer"
                    onClick={handleStepCount(true)}
                  >
                    <PlusIcon
                      className="block h-8 w-8 text-gray-400"
                      aria-hidden="true"
                    />
                  </div>
                </div>
              ) : null}
            </div>
          </div>
          {/* Tips */}
          <div className="my-16">
            <div className="my-4">
              <label
                htmlFor="knack"
                className="block text-md font-medium text-gray-700"
              >
                レシピのコツ
              </label>
              <div className="mt-2">
                <textarea
                  id="knack"
                  name="knack"
                  rows={4}
                  required
                  value={state.knack}
                  onChange={handleSimpleChange("knack")}
                  placeholder="レシピのコツ"
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-green-600 focus:border-green-600 sm:text-sm"
                />
              </div>
            </div>
          </div>
          {/* Actions */}
          <div className="flex gap-x-6 mb-10">
            {loading ? (
              <button
                className="flex justify-center items-center gap-x-3 py-3 px-8 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-green-800"
                type="submit"
                disabled
              >
                <div className="animate-spin h-5 w-5 border-4 border-gray-200 rounded-full border-t-transparent"></div>
                アップロード中
              </button>
            ) : (
              <button
                className="flex justify-center py-3 px-8 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-green-700 hover:bg-green-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-600"
                type="submit"
              >
                レシピを公開
              </button>
            )}
            <Link
              to={generateRoute(id, "profile")}
              className="flex justify-center py-3 px-8 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-gray-400 hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-300"
            >
              キャンセル
            </Link>
          </div>
        </div>
        <div className="flex flex-col items-center md:items-end col-span-4 md:col-span-1">
          <Ad />
        </div>
      </form>
      <Transition appear show={modal} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-10 overflow-y-auto"
          onClose={() => setModal(false)}
        >
          <div className="min-h-screen px-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="inline-block w-full max-w-md p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                <Dialog.Title
                  as="h3"
                  className="flex justify-center text-xl mb-3 font-medium text-gray-900"
                >
                  Cut the image
                </Dialog.Title>
                <Cropper
                  style={{ height: 400, width: "100%" }}
                  zoomTo={0.5}
                  initialAspectRatio={1}
                  src={image}
                  viewMode={1}
                  minCropBoxHeight={10}
                  minCropBoxWidth={10}
                  background={false}
                  responsive={true}
                  autoCropArea={1}
                  checkOrientation={false}
                  onInitialized={(instance) => {
                    setCropper(instance);
                  }}
                  guides={true}
                />

                <div className="mt-4 flex justify-center">
                  <button
                    type="button"
                    className="inline-flex justify-center px-4 py-2 text-sm font-medium text-gray-100 bg-green-700 border border-transparent rounded-md hover:bg-green-800 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-green-600"
                    onClick={handleGallery}
                  >
                    このサイズにカットします
                  </button>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

const Post = () => {
  const { data: user } = useUser();
  const { userList } = useDataContext();
  const userData = userList.find((i) => i.email === user?.email);

  if (user && userData) {
    return (
      <Layout title="Post">
        <div className="max-w-7xl mx-auto border-x-2">
          <ProfileHeader userData={userData} path={Menu.Post} />
          <ProfileContext.Provider value={userData}>
            <AuthPost />
          </ProfileContext.Provider>
        </div>
      </Layout>
    );
  } else {
    return <Navigate replace to={routes.signin} />;
  }
};

export default Post;
