const routes = {
  home: "/",
  reset: "/reset",
  signin: "/signin",
  signup: "/signup",
  about: "/about",
  all: "/all",
  profile: "/user/:userId",
  favourites: "/user/:userId/favourites",
  myrecipe: "/user/:userId/myrecipe",
  friends: "/user/:userId/friends",
  album: "/user/:userId/album",
  albumDetail: "/user/:userId/album/:albumId",
  post: "/post",
  recipe: "/recipe/:recipeId",
  privacy: "/files/privacy.pdf",
  agreement: "/files/agreement.pdf",
  notFound: "/404",
};

export const generateRoute = (userId: string, type: string) => {
  return type === "profile" ? `/user/${userId}` : `/user/${userId}/${type}`;
};

export const generateAlbumRoute = (userId: string, albumId: string) => {
  return `/user/${userId}/album/${albumId}`;
};

export default routes;
