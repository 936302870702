import { Dialog, Transition } from "@headlessui/react";
import { Layout, ProfileLayout } from "components";
import { useProfileContext } from "components/ProfileLayout";
import { doc, updateDoc } from "firebase/firestore";
import { ref, uploadString } from "firebase/storage";
import { Fragment, useState } from "react";
import Cropper from "react-cropper";
import {
  useFirestore,
  useStorage,
  useStorageDownloadURL,
  useUser,
} from "reactfire";
import { Pet } from "types/collection";
import {
  COLLECTION_SET,
  DATE_SET,
  DEFAULT_AVATAR,
  DEFAULT_PET,
  DOG_CATEGORY,
  GENDER_OPTIONS,
  IMAGE_TYPE,
  JAPAN_AREAS,
  JAPAN_PREFECTURES,
  Menu,
  UPLOAD_TYPE,
} from "utils/constants";
import { isBase64 } from "utils/misc";

enum ButtonAction {
  open,
  save,
  cancel,
}

interface FormData {
  familyName: string | undefined;
  givenName: string | undefined;
  familyNameFurigana: string | undefined;
  givenNameFurigana: string | undefined;
  gender: string | undefined;
  year: string | undefined;
  month: string | undefined;
  day: string | undefined;
  email: string | undefined;
  prefecture: keyof typeof JAPAN_AREAS | "" | undefined;
  area: string | undefined;
  avatar: string | undefined;
  fileName: string | undefined;
}

interface PetFormData {
  name: string | undefined;
  category: string | undefined;
  year: string | undefined;
  month: string | undefined;
  day: string | undefined;
  gender: string | undefined;
  avatar: string | undefined;
  fileName: string | undefined;
}

interface PetItemProps {
  pet: Pet;
}

const PetItem = ({ pet }: PetItemProps) => {
  const { name, avatar, category, birth } = pet;
  const storage = useStorage();
  const petRef = ref(storage, avatar);
  const { data: petUrl } = useStorageDownloadURL(petRef);

  return (
    <div className="flex flex-col md:flex-row mt-10 items-center gap-x-6">
      <img src={petUrl} alt="Demo" className="w-24 rounded-full" />
      <div className="mt-4 md:mt-0">
        <p>{name}</p>
        <p>{category}</p>
        <p>
          {new Date().getFullYear() - Number(birth.split("-")[0])}
          {` `}歳
        </p>
      </div>
    </div>
  );
};

interface PetAvatarProps {
  avatar: string;
}

const PetAvatar = ({ avatar }: PetAvatarProps) => {
  const storage = useStorage();
  const petRef = ref(storage, avatar ? avatar : DEFAULT_AVATAR.PET);
  const { data: avatarUrl } = useStorageDownloadURL(petRef);

  return <img src={avatarUrl} alt="Pet" className="w-24 rounded-full" />;
};

const ProfileInner = () => {
  const {
    avatar,
    pets,
    id,
    email,
    familyName,
    familyNameFurigana,
    givenName,
    givenNameFurigana,
    gender,
    birth,
    area,
    prefecture,
    nickname,
  } = useProfileContext();
  const { data: user } = useUser();

  const storage = useStorage();
  const avatarRef = ref(storage, avatar);
  const { data: avatarUrl } = useStorageDownloadURL(avatarRef);

  const initialUserProfile = {
    familyName,
    givenName,
    familyNameFurigana,
    givenNameFurigana,
    gender,
    email,
    prefecture: prefecture as any,
    area,
    year: birth.split("-")[0],
    month: birth.split("-")[1],
    day: birth.split("-")[2],
    avatar: avatarUrl,
    fileName: "",
  };

  const initialPetProfileList = pets.length
    ? pets.map(({ name, category, birth, gender, avatar }) => ({
        name,
        category,
        gender,
        avatar,
        year: birth.split("-")[0],
        month: birth.split("-")[1],
        day: birth.split("-")[2],
        fileName: "",
      }))
    : [DEFAULT_PET];

  const [state, setState] = useState<FormData>(initialUserProfile);
  const [petState, setPetState] = useState<PetFormData[]>(
    initialPetProfileList
  );
  const [selectedPet, setSelectedPet] = useState<number | undefined>(undefined);
  const handleAdd = () => {
    setPetState([...petState, DEFAULT_PET]);
  };

  const handleChange = (name: keyof FormData) => (e: any) => {
    setState({
      ...state,
      [name]: e.target.value,
    });
  };

  const handlePetChange =
    (name: keyof PetFormData, petIndex: number) => (e: any) => {
      setPetState(
        petState.map((pet, index) =>
          index === petIndex
            ? {
                ...pet,
                [name]: e.target.value,
              }
            : pet
        )
      );
    };

  const [modal, setModal] = useState(false);
  const [image, setImage] = useState("");
  const [cropper, setCropper] = useState<any>();
  const onChange = (petIndex?: number) => (e: any) => {
    e.preventDefault();
    let files: any;
    if (e.dataTransfer) {
      files = e.dataTransfer.files;
    } else if (e.target) {
      files = e.target.files;
    }
    const reader = new FileReader();
    reader.onload = () => {
      setImage(reader.result as any);
    };
    reader.readAsDataURL(files[0]);
    if (typeof petIndex === "number") {
      setSelectedPet(petIndex);
      setPetState(
        petState.map((pet, index) =>
          index === petIndex
            ? {
                ...pet,
                fileName: files[0].name,
              }
            : pet
        )
      );
    } else {
      setState({ ...state, fileName: files[0].name });
    }
    setModal(true);
  };
  const handleUpload = async () => {
    if (typeof cropper !== "undefined") {
      if (typeof selectedPet === "number") {
        setPetState(
          petState.map((pet, index) =>
            index === selectedPet
              ? {
                  ...pet,
                  avatar: cropper.getCroppedCanvas().toDataURL(),
                }
              : pet
          )
        );
      } else {
        setState({
          ...state,
          avatar: cropper.getCroppedCanvas().toDataURL(),
        });
      }
    }
    setModal(false);
  };

  const [editUser, setEditUser] = useState(false);
  const handleEditUser = (actionType: ButtonAction) => () => {
    setEditUser(actionType === ButtonAction.open ? true : false);
    if (ButtonAction.cancel) {
      setState(initialUserProfile);
    }
  };

  const [editPet, setEditPet] = useState(false);
  const handleEditPet = (actionType: ButtonAction) => () => {
    setEditPet(actionType === ButtonAction.open ? true : false);
    if (ButtonAction.cancel) {
      setPetState(initialPetProfileList);
    }
  };

  const firestore = useFirestore();
  const handleSubmit = async (e: any) => {
    e.preventDefault();
    const newData = {
      familyName: state.familyName,
      givenName: state.givenName,
      familyNameFurigana: state.familyNameFurigana,
      givenNameFurigana: state.givenNameFurigana,
      gender: state.gender,
      birth: `${state.year}-${state.month}-${state.day}`,
      email: state.email,
      prefecture: state.prefecture as any,
      area: state.area,
    };
    const userRef = doc(firestore, COLLECTION_SET.USERS, id);
    if (state.fileName) {
      const storageRef = ref(
        storage,
        `${IMAGE_TYPE.AVATARS}/${id}/${state.fileName}`
      );
      await uploadString(storageRef, state.avatar as any, UPLOAD_TYPE);
      await updateDoc(userRef, {
        ...newData,
        avatar: `${IMAGE_TYPE.AVATARS}/${id}/${state.fileName}`,
      });
    } else {
      await updateDoc(userRef, newData);
    }
    handleEditUser(ButtonAction.save)();
  };

  const handlePetSubmit = async (e: any) => {
    e.preventDefault();
    const userRef = doc(firestore, COLLECTION_SET.USERS, id);
    const newData = petState.map(
      ({ name, category, gender, year, month, day, fileName, avatar }) => ({
        name,
        category,
        gender,
        birth: `${year}-${month}-${day}`,
        avatar: fileName ? `${IMAGE_TYPE.PETS}/${id}/${fileName}` : avatar,
      })
    );

    for (const { fileName, avatar } of petState) {
      if (fileName) {
        const storageRef = ref(storage, `${IMAGE_TYPE.PETS}/${id}/${fileName}`);
        await uploadString(storageRef, avatar as any, UPLOAD_TYPE);
      }
    }
    await updateDoc(userRef, { pets: newData });
    handleEditPet(ButtonAction.save)();
  };

  return (
    <>
      <div className="px-16 grid grid-cols-4 py-16">
        <div className="col-span-4 md:col-span-3">
          {/* User Profile */}
          {editUser ? null : (
            <div className="p-10 ml-0 md:ml-6 bg-orange-50">
              <div className="flex flex-col md:flex-row justify-between">
                <h1 className="text-lg md:text-3xl font-bold text-gray-900">
                  個人プロフィール
                </h1>
                {user && user.email === email ? (
                  <button
                    onClick={handleEditUser(ButtonAction.open)}
                    className="text-yellow-500 hover:text-yellow-600"
                  >
                    Edit
                  </button>
                ) : null}
              </div>
              <div className="flex flex-col md:flex-row mt-10 items-center gap-x-6">
                <img
                  src={avatarUrl}
                  alt={nickname}
                  className="w-24 rounded-full"
                />
                <div className="mt-4 md:mt-0">
                  <p>{`${familyName} ${givenName}`}</p>
                  <p>{`${familyNameFurigana} ${givenNameFurigana}`}</p>
                </div>
              </div>
            </div>
          )}

          {/* Eidt User Profile */}
          {editUser ? (
            <form
              className="p-10 ml-0 md:ml-6 bg-orange-50"
              onSubmit={handleSubmit}
            >
              <div className="flex flex-col md:flex-row justify-between">
                <h1 className="text-lg md:text-3xl font-bold text-gray-900">
                  個人プロフィール
                </h1>
              </div>
              <div>
                <h2 className="text-lg font-medium mt-10 mb-3">
                  プロフィール写真
                </h2>
                <div className="flex flex-col md:flex-row items-center gap-x-6">
                  <img
                    src={state.avatar}
                    alt={nickname}
                    className="w-24 rounded-full"
                  />
                  <label className="font-medium text-yellow-500 hover:text-yellow-400">
                    プロフィール写真を変更
                    <input
                      type="file"
                      accept="image/*"
                      className="hidden"
                      onChange={onChange()}
                    />
                  </label>
                </div>
              </div>
              <div className="grid grid-cols-1 md:grid-cols-2 mt-3 py-6 gap-y-5 gap-x-16">
                <div>
                  <h2 className="text-lg font-medium mb-3">名前</h2>
                  <div className="grid grid-cols-2 gap-x-3">
                    <input
                      type="text"
                      name="familyName"
                      placeholder="姓"
                      required
                      value={state.familyName}
                      onChange={handleChange("familyName")}
                      className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-green-600 focus:border-green-600 sm:text-sm"
                    />
                    <input
                      type="text"
                      name="givenName"
                      placeholder="名"
                      required
                      value={state.givenName}
                      onChange={handleChange("givenName")}
                      className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-green-600 focus:border-green-600 sm:text-sm"
                    />
                  </div>
                </div>
                <div>
                  <h2 className="text-lg font-medium mb-3">ふりがな</h2>
                  <div className="grid grid-cols-2 gap-x-3">
                    <input
                      type="text"
                      name="familyNameFurigana"
                      placeholder="セイ"
                      required
                      value={state.familyNameFurigana}
                      onChange={handleChange("familyNameFurigana")}
                      className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-green-600 focus:border-green-600 sm:text-sm"
                    />
                    <input
                      type="text"
                      name="givenNameFurigana"
                      placeholder="メイ"
                      required
                      value={state.givenNameFurigana}
                      onChange={handleChange("givenNameFurigana")}
                      className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-green-600 focus:border-green-600 sm:text-sm"
                    />
                  </div>
                </div>
                <div>
                  <h2 className="text-lg font-medium mb-3">性別</h2>
                  <div className="grid grid-cols-2 gap-3">
                    {GENDER_OPTIONS.map((option) => (
                      <label
                        key={option.value}
                        className={`${
                          state.gender === option.value
                            ? "bg-green-700 border-green-600 text-gray-100 hover:bg-green-800"
                            : "bg-white border-gray-200 text-gray-900 hover:bg-gray-50"
                        } border rounded-md py-2 px-3 flex items-center justify-center text-sm font-medium uppercase sm:flex-1 cursor-pointer focus:outline-none`}
                      >
                        {option.label}
                        <input
                          name="gender"
                          type="radio"
                          className="hidden"
                          value={option.value}
                          onClick={handleChange("gender")}
                        ></input>
                      </label>
                    ))}
                  </div>
                </div>
                <div>
                  <h2 className="text-lg font-medium mb-3">生年月日</h2>
                  <div className="grid grid-cols-4 gap-3">
                    <select
                      name="year"
                      className="col-span-4 md:col-span-2 appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-green-600 focus:border-green-600 sm:text-sm"
                      value={state.year}
                      onChange={handleChange("year")}
                    >
                      {DATE_SET("year").map(({ value, label }) => (
                        <option key={value} value={value}>
                          {label}
                        </option>
                      ))}
                    </select>
                    <select
                      name="month"
                      className="col-span-2 md:col-span-1 appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-green-600 focus:border-green-600 sm:text-sm"
                      value={state.month}
                      onChange={handleChange("month")}
                    >
                      {DATE_SET("month").map(({ value, label }) => (
                        <option key={value} value={value}>
                          {label}
                        </option>
                      ))}
                    </select>
                    <select
                      name="day"
                      className="col-span-2 md:col-span-1 appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-green-600 focus:border-green-600 sm:text-sm"
                      value={state.day}
                      onChange={handleChange("day")}
                    >
                      {DATE_SET("day").map(({ value, label }) => (
                        <option key={value} value={value}>
                          {label}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div>
                  <h2 className="text-lg font-medium mb-3">メールアドレス</h2>
                  <input
                    type="text"
                    name="email"
                    placeholder="メールアドレス"
                    required
                    disabled
                    value={state.email}
                    onChange={handleChange("email")}
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-green-600 focus:border-green-600 sm:text-sm opacity-25 cursor-not-allowed"
                  />
                </div>
                <div>
                  <h2 className="text-lg font-medium mb-3">住所</h2>
                  <div className="grid grid-cols-2 gap-x-3">
                    <select
                      name="prefecture"
                      className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-green-600 focus:border-green-600 sm:text-sm"
                      value={state.prefecture}
                      onChange={handleChange("prefecture")}
                    >
                      <option value="">都道府県</option>
                      {JAPAN_PREFECTURES.map((i) => (
                        <option key={i} value={i}>
                          {i}
                        </option>
                      ))}
                    </select>
                    <select
                      name="area"
                      className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-green-600 focus:border-green-600 sm:text-sm"
                      value={state.area}
                      onChange={handleChange("area")}
                    >
                      <option value="">市区町村</option>
                      {state.prefecture && JAPAN_AREAS[state.prefecture]
                        ? JAPAN_AREAS[state.prefecture].map((i) => (
                            <option key={i} value={i}>
                              {i}
                            </option>
                          ))
                        : []}
                    </select>
                  </div>
                </div>
              </div>
              <div className="mt-5 flex flex-col md:flex-row gap-6">
                <button
                  type="submit"
                  className="py-2 px-6 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-green-700 hover:bg-green-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-600"
                >
                  情報更新
                </button>
                <button
                  className="py-2 px-6 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-gray-400 hover:bg-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-300"
                  onClick={handleEditUser(ButtonAction.cancel)}
                >
                  キャンセル
                </button>
              </div>
            </form>
          ) : null}

          {/* Pet Profile */}
          {!editPet ? (
            <div className="p-10 ml-0 md:ml-6 mt-12 bg-orange-50">
              <div className="flex flex-col md:flex-row justify-between">
                <h1 className="text-lg md:text-3xl font-bold text-gray-900">
                  愛犬プロフィール
                </h1>
                {user && user.email === email ? (
                  <button
                    onClick={handleEditPet(ButtonAction.open)}
                    className="text-yellow-500 hover:text-yellow-600"
                  >
                    Edit
                  </button>
                ) : null}
              </div>
              <div className="flex gap-x-8">
                {pets.length
                  ? pets.map((pet) => <PetItem key={pet.name} pet={pet} />)
                  : null}
              </div>
            </div>
          ) : null}

          {/* Edit Pet Profile */}
          {editPet ? (
            <form
              className="p-10 ml-0 md:ml-6 mt-12 bg-orange-50"
              onSubmit={handlePetSubmit}
            >
              <div className="flex flex-col md:flex-row justify-between">
                <h1 className="text-lg md:text-3xl font-bold text-gray-900">
                  愛犬プロフィール
                </h1>
              </div>
              {petState.map(
                (
                  { avatar, name, category, year, month, day, gender },
                  index
                ) => (
                  <Fragment key={name}>
                    <div>
                      <h2 className="text-lg font-medium mt-10 mb-3">
                        プロフィール写真
                      </h2>
                      <div className="flex flex-col md:flex-row items-center gap-x-6">
                        {typeof avatar !== "undefined" ? (
                          isBase64(avatar) ? (
                            <img
                              src={avatar}
                              alt="Pet"
                              className="w-24 rounded-full"
                            />
                          ) : (
                            <PetAvatar avatar={avatar} />
                          )
                        ) : null}
                        <label className="font-medium text-yellow-500 hover:text-yellow-400">
                          プロフィール写真を変更
                          <input
                            type="file"
                            accept="image/*"
                            className="hidden"
                            onChange={onChange(index)}
                          />
                        </label>
                      </div>
                    </div>
                    <div className="grid grid-cols-1 md:grid-cols-2 mt-3 py-6 gap-y-5 gap-x-16">
                      <div>
                        <h2 className="text-lg font-medium mb-3">名前</h2>
                        <input
                          type="text"
                          name="name"
                          placeholder="名前"
                          autoFocus
                          required
                          value={name}
                          onChange={handlePetChange("name", index)}
                          className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-green-600 focus:border-green-600 sm:text-sm"
                        />
                      </div>
                      <div>
                        <h2 className="text-lg font-medium mb-3">犬種</h2>
                        <select
                          className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-green-600 focus:border-green-600 sm:text-sm"
                          value={category}
                          name="category"
                          onChange={handlePetChange("category", index)}
                        >
                          <option value="" disabled>
                            犬種
                          </option>
                          {DOG_CATEGORY.slice(1).map(({ value, label }) => (
                            <option key={value} value={value}>
                              {label}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div>
                        <h2 className="text-lg font-medium mb-3">性別</h2>
                        <div className="grid grid-cols-2 gap-3">
                          {GENDER_OPTIONS.map((option) => (
                            <label
                              key={option.value}
                              className={`${
                                gender === option.value
                                  ? "bg-green-700 border-green-600 text-gray-100 hover:bg-green-800"
                                  : "bg-white border-gray-200 text-gray-900 hover:bg-gray-50"
                              } border rounded-md py-2 px-3 flex items-center justify-center text-sm font-medium uppercase sm:flex-1 cursor-pointer focus:outline-none`}
                            >
                              {option.label}
                              <input
                                name="gender"
                                type="radio"
                                className="hidden"
                                value={option.value}
                                onChange={handlePetChange("gender", index)}
                              ></input>
                            </label>
                          ))}
                        </div>
                      </div>
                      <div>
                        <h2 className="text-lg font-medium mb-3">生年月日</h2>
                        <div className="grid grid-cols-4 gap-3">
                          <select
                            name="year"
                            className="col-span-4 md:col-span-2 appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-green-600 focus:border-green-600 sm:text-sm"
                            value={year}
                            onChange={handlePetChange("year", index)}
                          >
                            {DATE_SET("year").map(({ value, label }) => (
                              <option key={value} value={value}>
                                {label}
                              </option>
                            ))}
                          </select>
                          <select
                            name="month"
                            className="col-span-2 md:col-span-1 appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-green-600 focus:border-green-600 sm:text-sm"
                            value={month}
                            onChange={handlePetChange("month", index)}
                          >
                            {DATE_SET("month").map(({ value, label }) => (
                              <option key={value} value={value}>
                                {label}
                              </option>
                            ))}
                          </select>
                          <select
                            name="day"
                            className="col-span-2 md:col-span-1 appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-green-600 focus:border-green-600 sm:text-sm"
                            value={day}
                            onChange={handlePetChange("day", index)}
                          >
                            {DATE_SET("day").map(({ value, label }) => (
                              <option key={value} value={value}>
                                {label}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                    </div>
                  </Fragment>
                )
              )}
              <div className="mt-5 flex flex-col md:flex-row gap-6">
                <button
                  type="submit"
                  className="py-2 px-6 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-green-700 hover:bg-green-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-600"
                >
                  情報更新
                </button>
                <button
                  className="py-2 px-6 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-gray-400 hover:bg-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-300"
                  onClick={handleEditPet(ButtonAction.cancel)}
                >
                  キャンセル
                </button>
              </div>
              {pets.length < 2 ? (
                <button
                  onClick={handleAdd}
                  className="mt-10 py-2 px-6 border border-green-700 border-dashed rounded-md shadow-sm text-sm font-medium text-green-700 hover:bg-orange-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-600 mr-6"
                >
                  愛犬追加
                </button>
              ) : null}
            </form>
          ) : null}
        </div>
        <div></div>
      </div>
      <Transition appear show={modal} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-10 overflow-y-auto"
          onClose={() => setModal(false)}
        >
          <div className="min-h-screen px-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="inline-block w-full max-w-md p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                <Dialog.Title
                  as="h3"
                  className="flex justify-center text-xl mb-3 font-medium text-gray-900"
                >
                  Cut the image
                </Dialog.Title>
                <Cropper
                  style={{ height: 400, width: "100%" }}
                  zoomTo={0.5}
                  initialAspectRatio={1}
                  src={image}
                  viewMode={1}
                  minCropBoxHeight={10}
                  minCropBoxWidth={10}
                  background={false}
                  responsive={true}
                  autoCropArea={1}
                  checkOrientation={false}
                  onInitialized={(instance) => {
                    setCropper(instance);
                  }}
                  guides={true}
                />

                <div className="mt-4 flex justify-center">
                  <button
                    type="button"
                    className="inline-flex justify-center px-4 py-2 text-sm font-medium text-gray-100 bg-green-700 border border-transparent rounded-md hover:bg-green-800 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-green-600"
                    onClick={handleUpload}
                  >
                    このサイズにカットします
                  </button>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

const Profile = () => {
  return (
    <Layout title="Profile">
      <ProfileLayout path={Menu.Profile}>
        <ProfileInner />
      </ProfileLayout>
    </Layout>
  );
};

export default Profile;
