import { Layout, MyPagination, ProfileLayout, Section } from "components";
import { useProfileContext } from "components/ProfileLayout";
import { useDataContext } from "providers";
import { useState } from "react";
import { ALL_PER_SECTION, Menu } from "utils/constants";

const FavouritesInner = () => {
  const { favourites } = useProfileContext();
  const { recipeList } = useDataContext();

  const [page, setPage] = useState(1);
  const handlePageChange = (_: any, value: number) => {
    setPage(value);
  };

  const recipeRawData = favourites.map((fav) =>
    recipeList.find((i) => i.id === fav)
  );
  const length = Math.ceil(recipeRawData.length / ALL_PER_SECTION);
  const recipeData = recipeRawData.slice(
    (page - 1) * ALL_PER_SECTION,
    page * ALL_PER_SECTION
  );

  return (
    <Section recipeList={recipeData}>
      <MyPagination count={length} page={page} onChange={handlePageChange} />
    </Section>
  );
};

const Favourites = () => {
  return (
    <Layout title="Favourites">
      <ProfileLayout path={Menu.Favourites}>
        <FavouritesInner />
      </ProfileLayout>
    </Layout>
  );
};

export default Favourites;
