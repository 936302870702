import React from "react";
import { Error } from "pages";
import { ERROR_PROPS } from "utils/constants";

interface State {
  hasError: boolean;
}

class ErrorBoundary extends React.Component<object, State> {
  constructor(props: object) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch() {
    this.setState({ hasError: true });
  }

  render() {
    const { hasError } = this.state;

    if (hasError) {
      return <Error {...ERROR_PROPS.ERROR} />;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
