import { collection, query } from "firebase/firestore";
import { createContext, FC, useContext } from "react";
import { useFirestore, useFirestoreCollectionData } from "reactfire";
import { Favourite, Recipe, User } from "types/collection";
import { DEFAULT_AVATAR, COLLECTION_SET } from "../utils/constants";

interface Data {
  recipeList: Recipe[];
  userList: User[];
  favouriteSet: Favourite;
}

const DataContext = createContext<Data>({
  recipeList: [],
  userList: [],
  favouriteSet: {},
});

const useDataContext = () => {
  return useContext(DataContext);
};

const DataProvider: FC = ({ children }) => {
  const firestore = useFirestore();
  const recipesCollection = collection(firestore, COLLECTION_SET.RECIPES);
  const recipesQuery = query<Recipe>(recipesCollection as any);
  const usersCollection = collection(firestore, COLLECTION_SET.USERS);
  const usersQuery = query<User>(usersCollection as any);

  const { data: recipesRawData } = useFirestoreCollectionData<Recipe>(
    recipesQuery,
    {
      idField: "id",
    }
  );
  const { data: usersRawData } = useFirestoreCollectionData<User>(usersQuery, {
    idField: "id",
  });

  const recipeList = recipesRawData
    .map((recipe) => ({
      ...recipe,
      timestamp: recipe.timestamp ?? 0,
    }))
    .sort((a, b) => b.timestamp - a.timestamp);
  const userList = usersRawData.map((user) => ({
    ...user,
    avatar: user.avatar ? user.avatar : DEFAULT_AVATAR.BIG,
    pets: user.pets.map((i) => ({
      ...i,
      avatar: i.avatar ? i.avatar : DEFAULT_AVATAR.PET,
    })),
    followings: usersRawData.reduce((result, current) => {
      return current.followers.includes(user.id)
        ? [...result, current.id]
        : result;
    }, [] as string[]),
  }));

  const favouriteSet = userList.reduce((result, current) => {
    current.favourites.forEach((i: string) => {
      result[i] ? (result[i] += 1) : (result[i] = 1);
    });
    return result;
  }, {} as Favourite);

  return (
    <DataContext.Provider value={{ recipeList, userList, favouriteSet }}>
      {children}
    </DataContext.Provider>
  );
};

export { useDataContext, DataProvider };
