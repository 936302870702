import React from "react";
import { FirebaseAppProvider, SuspenseWithPerf } from "reactfire";
import firebaseConfig from "utils/firebaseConfig";
import { Loading } from "components";

const FirebaseProvider: React.FC = ({ children }) => {
  return (
    <FirebaseAppProvider firebaseConfig={firebaseConfig} suspense>
      <SuspenseWithPerf fallback={<Loading />} traceId="load-projects-status">
        {children}
      </SuspenseWithPerf>
    </FirebaseAppProvider>
  );
};

export default FirebaseProvider;
