import { Ad, Layout, ProfileLayout } from "components";
import { useProfileContext } from "components/ProfileLayout";
import { doc, updateDoc } from "firebase/firestore";
import { deleteObject, ref } from "firebase/storage";
import { useNavigate, useParams } from "react-router-dom";
import {
  useFirestore,
  useStorage,
  useStorageDownloadURL,
  useUser,
} from "reactfire";
import { COLLECTION_SET, IMAGE_TYPE, Menu } from "utils/constants";
import { generateRoute } from "utils/routes";

const AlbumDetailInner = () => {
  const navigate = useNavigate();
  const { id, photos, email } = useProfileContext();
  const { albumId } = useParams();
  const { data: user } = useUser();

  const firestore = useFirestore();
  const storage = useStorage();
  const coverRef = ref(storage, `${IMAGE_TYPE.PHOTOS}/${albumId}`);
  const { data: coverUrl } = useStorageDownloadURL(coverRef);

  const handleDelete = async () => {
    const userRef = doc(firestore, COLLECTION_SET.USERS, id);
    await deleteObject(coverRef);
    await updateDoc(userRef, {
      photos: photos.filter((i) => i !== `${IMAGE_TYPE.PHOTOS}/${albumId}`),
    });
    navigate(generateRoute(id, Menu.Album));
  };

  return (
    <div className="px-16 grid grid-cols-4 my-16">
      <div className="mb-10 md:mb-0 col-span-4 md:col-span-3">
        <div className="grid grid-cols-5">
          <img src={coverUrl} alt="Album" className="col-span-4 rounded-lg" />
        </div>
        {user && user.email === email ? (
          <button
            className="mt-3 font-medium text-yellow-500 hover:text-yellow-400"
            onClick={handleDelete}
          >
            Delete
          </button>
        ) : null}
      </div>
      <div className="flex flex-col items-center md:items-end col-span-4 md:col-span-1">
        <Ad />
      </div>
    </div>
  );
};

const AlbumDetail = () => {
  return (
    <Layout title="Album">
      <ProfileLayout path={Menu.Album}>
        <AlbumDetailInner />
      </ProfileLayout>
    </Layout>
  );
};

export default AlbumDetail;
