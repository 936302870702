import { ErrorBoundary, Notification, ScrollReset } from "components";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import {
  About,
  Album,
  AlbumDetail,
  All,
  Error,
  Favourites,
  Friends,
  Home,
  MyRecipe,
  Post,
  Profile,
  RecipeDetail,
  Reset,
  SignIn,
  SignUp,
} from "pages";
import { DataProvider } from "providers";
import { useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import {
  AuthProvider,
  FirestoreProvider,
  StorageProvider,
  useFirebaseApp,
  useInitPerformance,
} from "reactfire";
import { ERROR_PROPS } from "utils/constants";
import routes from "utils/routes";

function App() {
  const [show, setShow] = useState(false);

  const firebaseApp = useFirebaseApp();
  const auth = getAuth(firebaseApp);
  const firestoreInstance = getFirestore(useFirebaseApp());

  useInitPerformance(
    async (firebaseApp) => {
      const { getPerformance } = await import("firebase/performance");
      return getPerformance(firebaseApp);
    },
    { suspense: false } // false because we don't want to stop render while we wait for perf
  );

  return (
    <AuthProvider sdk={auth}>
      <FirestoreProvider sdk={firestoreInstance}>
        <StorageProvider sdk={getStorage(firebaseApp)}>
          <DataProvider>
            <Router>
              <ErrorBoundary>
                <ScrollReset />
                <Notification show={show} setShow={setShow} />
                <Routes>
                  <Route path={routes.signup} element={<SignUp />} />
                  <Route path={routes.signin} element={<SignIn />} />
                  <Route path={routes.reset} element={<Reset />} />
                  <Route path={routes.home} element={<Home />} />
                  <Route path={routes.about} element={<About />} />
                  <Route path={routes.all} element={<All />} />
                  <Route path={routes.recipe} element={<RecipeDetail />} />
                  <Route path={routes.favourites} element={<Favourites />} />
                  <Route path={routes.album} element={<Album />} />
                  <Route path={routes.albumDetail} element={<AlbumDetail />} />
                  <Route path={routes.friends} element={<Friends />} />
                  <Route path={routes.myrecipe} element={<MyRecipe />} />
                  <Route path={routes.profile} element={<Profile />} />
                  <Route path={routes.post} element={<Post />} />
                  <Route
                    path="*"
                    element={<Error {...ERROR_PROPS.NOT_FOUND} />}
                  />
                </Routes>
              </ErrorBoundary>
            </Router>
          </DataProvider>
        </StorageProvider>
      </FirestoreProvider>
    </AuthProvider>
  );
}

export default App;
