import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination } from "swiper";

import "swiper/css";
import "swiper/css/pagination";

import slideOneImage from "static/slides/01.jpeg";
import slideTwoImage from "static/slides/02.jpeg";
import slideThreeImage from "static/slides/03.jpeg";
import slideFourImage from "static/slides/04.jpeg";

const Slider = () => {
  return (
    <Swiper
      autoplay={{
        delay: 3500,
        disableOnInteraction: false,
      }}
      pagination={{
        clickable: true,
        renderBullet: function (_, className) {
          return `<span class="${className} w-10 h-1 bg-white rounded-none"></span>`;
        },
      }}
      modules={[Autoplay, Pagination]}
    >
      <SwiperSlide>
        <img src={slideOneImage} alt="Slide One" />
      </SwiperSlide>
      <SwiperSlide>
        <img src={slideTwoImage} alt="Slide Two" />
      </SwiperSlide>
      <SwiperSlide>
        <img src={slideThreeImage} alt="Slide Three" />
      </SwiperSlide>
      <SwiperSlide>
        <img src={slideFourImage} alt="Slide Four" />
      </SwiperSlide>
    </Swiper>
  );
};

export default Slider;
