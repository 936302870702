import { Link } from "react-router-dom";
import adOneImage from "static/img-ad.png";
import adTwoImage from "static/img-ad2.png";
import routes from "utils/routes";

const Ad = () => {
  return (
    <>
      {[adTwoImage, adOneImage].map((image, index) => (
        <Link to={routes.home} key={index}>
          <img src={image} alt="Ad" className="mb-8 rounded-lg" />
        </Link>
      ))}
    </>
  );
};

export default Ad;
