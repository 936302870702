import { Layout, Section, MyPagination } from "components";
import { ALL_PER_SECTION } from "utils/constants";
import allBannerImage from "static/img-recipe_mv.png";
import newestImage from "static/img-newest.png";
import popularImage from "static/img-popular.png";
import { useDataContext } from "providers";
import { useEffect, useState } from "react";
import { useUser } from "reactfire";
import { useSearchParams } from "react-router-dom";

const actionSet = [
  { label: "newest", image: newestImage },
  { label: "popular", image: popularImage },
];

const All = () => {
  const { data: user } = useUser();
  const { recipeList, userList, favouriteSet } = useDataContext();
  const currentUser = userList.find((i) => i.email === user?.email);

  const [searchParams] = useSearchParams();
  const query = searchParams.get("query");

  const category1 = searchParams.get("category1");
  const category2 = searchParams.get("category2");
  const age = searchParams.getAll("age");
  const purpose = searchParams.getAll("purpose");

  const [page, setPage] = useState(1);
  const handlePageChange = (_: any, value: number) => {
    setPage(value);
  };

  const [sortOption, setSortOption] = useState("newest");
  const handleClick = (sortType: string) => () => {
    setSortOption(sortType);
    setPage(1);
  };

  useEffect(() => {
    setSortOption("newest");
    setPage(1);
  }, [searchParams]);

  const dataAfterQuery = query
    ? recipeList.filter((recipe) => JSON.stringify(recipe).includes(query))
    : recipeList;

  const dataAfterFilter = dataAfterQuery.filter((recipe) => {
    const category1Filter = category1
      ? recipe.category === "all"
        ? true
        : recipe.category.includes(category1)
      : true;
    const category2Filter = category2
      ? recipe.category === "all"
        ? true
        : recipe.category.includes(category2)
      : true;
    const ageFilter = age.length
      ? recipe.age
        ? age.some((i) => recipe.age.includes(i))
        : true
      : true;
    const purposeFilter = purpose.length
      ? recipe.purpose
        ? purpose.some((i) => recipe.purpose.includes(i))
        : true
      : true;

    return category1Filter && category2Filter && ageFilter && purposeFilter;
  });

  const length = Math.ceil(dataAfterFilter.length / ALL_PER_SECTION);
  const recipeData = (
    sortOption === "newest"
      ? dataAfterFilter
      : [
          ...Object.keys(favouriteSet)
            .sort((a, b) => favouriteSet[b] - favouriteSet[a])
            .map((id) => dataAfterFilter.find((recipe) => recipe.id === id)),
          ...dataAfterFilter.filter((recipe) => !favouriteSet[recipe.id]),
        ]
  ).slice((page - 1) * ALL_PER_SECTION, page * ALL_PER_SECTION);

  return (
    <Layout title="All">
      <div className="max-w-7xl mx-auto border-x-2">
        <img src={allBannerImage} alt="All Banner" className="w-full" />
        <div className="mt-16 pl-16 flex gap-x-6">
          {actionSet.map(({ image, label }) => (
            <button
              key={label}
              className={sortOption === label ? "" : "opacity-70"}
              onClick={handleClick(label)}
            >
              <img
                src={image}
                alt={label}
                className="w-30 h-12 md:w-40 md:h-16"
              />
            </button>
          ))}
        </div>
        <Section recipeList={recipeData} currentUser={currentUser}>
          <MyPagination
            count={length}
            page={page}
            onChange={handlePageChange}
          />
        </Section>
      </div>
    </Layout>
  );
};

export default All;
