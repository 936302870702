import { Link } from "react-router-dom";
import routes from "utils/routes";
import { Layout } from "components";
import { EMAIL_ADDRESS } from "utils/constants";

interface Props {
  title: string;
  subtitle: string;
  description: string;
}

export default function NotFound({ title, subtitle, description }: Props) {
  return (
    <Layout title="Not Found">
      <div className="min-h-screen max-w-7xl mx-auto border-x-2 px-4 py-16 sm:px-6 sm:py-24 md:grid md:place-items-center lg:px-8">
        <div className="max-w-max mx-auto">
          <main className="sm:flex">
            <p className="text-4xl font-extrabold text-green-700 sm:text-5xl">
              {title}
            </p>
            <div className="sm:ml-6">
              <div className="sm:border-l sm:border-gray-200 sm:pl-6">
                <h1 className="text-4xl font-bold text-gray-600 tracking-tight sm:text-5xl">
                  {subtitle}
                </h1>
                <p className="mt-1 text-base text-gray-500">{description}</p>
              </div>
              <div className="mt-10 flex space-x-3 sm:border-l sm:border-transparent sm:pl-6">
                <Link
                  to={routes.home}
                  className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-green-700 hover:bg-green-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-600"
                >
                  Go back home
                </Link>
                <a
                  href={`mailto:${EMAIL_ADDRESS}`}
                  className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-green-700 bg-green-100 hover:bg-green-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-600"
                >
                  Contact support
                </a>
              </div>
            </div>
          </main>
        </div>
      </div>
    </Layout>
  );
}
