export const EMAIL_ADDRESS = "info@wango.life";

export const ERROR_PROPS = {
  NOT_FOUND: {
    title: "404",
    subtitle: "Page not found",
    description: "Please check the URL in the address bar and try again.",
  },
  ERROR: {
    title: "500",
    subtitle: "Something went wrong",
    description: "Please try again later.",
  },
};

export const HOME_PER_SECTION = 6;
export const ALL_PER_SECTION = 12;

export const DOG_CATEGORY = [
  {
    label: "全犬種",
    value: "all",
  },
  {
    label: "プードル(トイ・ミニチュア･ミディアム)",
    value: "プードル(トイ・ミニチュア･ミディアム)",
  },
  {
    label: "チワワ",
    value: "チワワ",
  },
  {
    label: "ダックスフンド(カニーンヘン･ミニチュア・スタンダード)",
    value: "ダックスフンド(カニーンヘン･ミニチュア・スタンダード)",
  },
  {
    label: "ポメラニアン",
    value: "ポメラニアン",
  },
  {
    label: "柴",
    value: "柴",
  },
  {
    label: "ヨークシャー・テリア",
    value: "ヨークシャー・テリア",
  },
  {
    label: "ミニチュア・シュナウザー",
    value: "ミニチュア・シュナウザー",
  },
  {
    label: "シー・ズー",
    value: "シー・ズー",
  },
  {
    label: "マルチーズ",
    value: "マルチーズ",
  },
  {
    label: "フレンチ・ブルドッグ",
    value: "フレンチ・ブルドッグ",
  },
  {
    label: "ウェルシュ・コーギー・ペンブローク",
    value: "ウェルシュ・コーギー・ペンブローク",
  },
  {
    label: "ゴールデン・レトリーバー",
    value: "ゴールデン・レトリーバー",
  },
  {
    label: "パピヨン",
    value: "パピヨン",
  },
  {
    label: "パグ",
    value: "パグ",
  },
  {
    label: "キャバリア・キング・チャールズ・スパニエル",
    value: "キャバリア・キング・チャールズ・スパニエル",
  },
  {
    label: "ジャック・ラッセル・テリア",
    value: "ジャック・ラッセル・テリア",
  },
  {
    label: "ラブラドール・レトリーバー",
    value: "ラブラドール・レトリーバー",
  },
  {
    label: "ミニチュア・ピンシャー",
    value: "ミニチュア・ピンシャー",
  },
  {
    label: "ペキニーズ",
    value: "ペキニーズ",
  },
  {
    label: "ビーグル",
    value: "ビーグル",
  },
  {
    label: "ボーダー・コリー",
    value: "ボーダー・コリー",
  },
  {
    label: "ビション・フリーゼ",
    value: "ビション・フリーゼ",
  },
  {
    label: "イタリアン・グレーハウンド",
    value: "イタリアン・グレーハウンド",
  },
  {
    label: "ボストン・テリア",
    value: "ボストン・テリア",
  },
  {
    label: "シェットランド・シープドッグ",
    value: "シェットランド・シープドッグ",
  },
  {
    label: "アメリカン・コッカー・スパニエル",
    value: "アメリカン・コッカー・スパニエル",
  },
  {
    label: "バーニーズ・マウンテン・ドッグ",
    value: "バーニーズ・マウンテン・ドッグ",
  },
  {
    label: "ブルドッグ",
    value: "ブルドッグ",
  },
  {
    label: "日本スピッツ",
    value: "日本スピッツ",
  },
  {
    label: "シベリアン・ハスキー",
    value: "シベリアン・ハスキー",
  },
  {
    label: "ウエスト・ハイランド・ホワイト・テリア",
    value: "ウエスト・ハイランド・ホワイト・テリア",
  },
  {
    label: "狆",
    value: "狆",
  },
  {
    label: "フラットコーテッド・レトリーバー",
    value: "フラットコーテッド・レトリーバー",
  },
  {
    label: "イングリッシュ・コッカー・スパニエル",
    value: "イングリッシュ・コッカー・スパニエル",
  },
  {
    label: "ドーベルマン",
    value: "ドーベルマン",
  },
  {
    label: "秋田",
    value: "秋田",
  },
  {
    label: "グレート・ピレニーズ",
    value: "グレート・ピレニーズ",
  },
  {
    label: "ボルゾイ",
    value: "ボルゾイ",
  },
  {
    label: "ノーフォーク・テリア",
    value: "ノーフォーク・テリア",
  },
  {
    label: "ウィペット",
    value: "ウィペット",
  },
  {
    label: "ダルメシアン",
    value: "ダルメシアン",
  },
  {
    label: "ジャーマン・シェパード・ドッグ",
    value: "ジャーマン・シェパード・ドッグ",
  },
  {
    label: "グレート・デーン",
    value: "グレート・デーン",
  },
  {
    label: "ボクサー",
    value: "ボクサー",
  },
  {
    label: "セント・バーナード",
    value: "セント・バーナード",
  },
  {
    label: "サモエド",
    value: "サモエド",
  },
  {
    label: "ロットワイラー",
    value: "ロットワイラー",
  },
  {
    label: "ワイマラナー",
    value: "ワイマラナー",
  },
  {
    label: "甲斐",
    value: "甲斐",
  },
  {
    label: "チャイニーズ・クレステッド・ドッグ",
    value: "チャイニーズ・クレステッド・ドッグ",
  },
  {
    label: "バセット・ハウンド",
    value: "バセット・ハウンド",
  },
  {
    label: "チャウ・チャウ",
    value: "チャウ・チャウ",
  },
  {
    label: "ニューファンドランド",
    value: "ニューファンドランド",
  },
  {
    label: "ワイアー・フォックス・テリア",
    value: "ワイアー・フォックス・テリア",
  },
  {
    label: "エアデール・テリア",
    value: "エアデール・テリア",
  },
  {
    label: "ミニチュア・ブル・テリア",
    value: "ミニチュア・ブル・テリア",
  },
  {
    label: "スコティッシュ・テリア",
    value: "スコティッシュ・テリア",
  },
  {
    label: "ウェルシュ・テリア",
    value: "ウェルシュ・テリア",
  },
  {
    label: "ラフ・コリー",
    value: "ラフ・コリー",
  },
  {
    label: "アイリッシュ・セター",
    value: "アイリッシュ・セター",
  },
  {
    label: "トイ・マンチェスター・テリア",
    value: "トイ・マンチェスター・テリア",
  },
  {
    label: "オーストラリアン・シェパード",
    value: "オーストラリアン・シェパード",
  },
  {
    label: "ホワイト・スイス・シェパード・ドッグ",
    value: "ホワイト・スイス・シェパード・ドッグ",
  },
  {
    label: "日本テリア",
    value: "日本テリア",
  },
  {
    label: "ブリュッセル・グリフォン",
    value: "ブリュッセル・グリフォン",
  },
  {
    label: "サルーキ",
    value: "サルーキ",
  },
  {
    label: "ケアーン・テリア",
    value: "ケアーン・テリア",
  },
  {
    label: "その他",
    value: "その他",
  },
];

export const AGE_SET = [
  { label: "~1歳", value: "0100" },
  { label: "1~3歳", value: "0103" },
  { label: "3~10歳", value: "0310" },
  { label: "10~15歳", value: "1015" },
  { label: "15歳~", value: "1500" },
];

export const PURPOSE_SET = [
  { label: "日常", value: "normal" },
  { label: "おやつ", value: "snack" },
  { label: "ダイエット", value: "diet" },
  { label: "アレルギー対策", value: "allergy" },
  { label: "夏バテ対策", value: "summer" },
  { label: "誕生日", value: "birthday" },
  { label: "キャンプ", value: "camp" },
  { label: "ハロウイン", value: "halloween" },
  { label: "クリスマス", value: "christmas" },
  { label: "お正月", value: "newyear" },
];

export const COLLECTION_SET = {
  RECIPES: "recipes",
  USERS: "users",
};

export const DEFAULT_AVATAR = {
  BIG: "avatars/default.png",
  SMALL: "avatars/avatar.png",
  PET: "pets/img-dog-paw.png",
};

export const IMAGE_TYPE = {
  AVATARS: "avatars",
  FILES: "files",
  GALLERIES: "galleries",
  PETS: "pets",
  PHOTOS: "photos",
  STEPS: "steps",
};

export const UPLOAD_TYPE = "data_url";

export const GENDER_OPTIONS = [
  { label: "男", value: "m" },
  { label: "女", value: "f" },
];

const DATE_LIMIT = {
  year: {
    min: 1920,
    max: new Date().getFullYear(),
    placeholder: "年",
  },
  month: {
    min: 1,
    max: 12,
    placeholder: "月",
  },
  day: {
    min: 1,
    max: 31,
    placeholder: "日",
  },
};

export const DATE_SET = (type: keyof typeof DATE_LIMIT) => {
  const { min, max, placeholder } = DATE_LIMIT[type];
  return [
    { label: placeholder, value: "" },
    ...Array(max - min + 1)
      .fill(0)
      .map((_, index) => ({
        label: `${min + index}`,
        value:
          `${min + index}`.length === 1 ? `0${min + index}` : `${min + index}`,
      })),
  ];
};

export const JAPAN_PREFECTURES = [
  "Aichi",
  "Akita",
  "Aomori",
  "Chiba",
  "Ehime",
  "Fukui",
  "Fukuoka",
  "Fukushima",
  "Gifu",
  "Gunma",
  "Hiroshima",
  "Hokkaidō",
  "Hyōgo",
  "Ibaraki",
  "Ishikawa",
  "Iwate",
  "Kagawa",
  "Kagoshima",
  "Kanagawa",
  "Kōchi",
  "Kumamoto",
  "Kyoto",
  "Mie",
  "Miyagi",
  "Miyazaki",
  "Nagano",
  "Nagasaki",
  "Nara",
  "Niigata",
  "Ōita",
  "Okayama",
  "Okinawa",
  "Osaka",
  "Saga",
  "Saitama",
  "Shiga",
  "Shimane",
  "Shizuoka",
  "Tochigi",
  "Tokushima",
  "Tokyo",
  "Tottori",
  "Toyama",
  "Wakayama",
  "Yamagata",
  "Yamaguchi",
  "Yamanashi",
];
export const JAPAN_AREAS = {
  Aichi: [
    "Nagoya",
    "Toyohashi",
    "Okazaki",
    "Ichinomiya",
    "Seto",
    "Handa",
    "Kasugai",
    "Toyokawa",
    "Tsushima",
    "Hekinan",
    "Kariya",
    "Toyota",
    "Anjō",
    "Nishio",
    "Gamagōri",
    "Inuyama",
    "Tokoname",
    "Kōnan",
    "Komaki",
    "Inazawa",
    "Tōkai",
    "Ōbu",
    "Chita",
    "Chiryū",
    "Owariasahi",
    "Takahama",
    "Iwakura",
    "Toyoake",
    "Nisshin",
    "Tahara",
    "Aisai",
    "Kiyosu",
    "Shinshiro",
    "Kitanagoya",
    "Yatomi",
    "Miyoshi",
    "Ama",
    "Nagakute",
  ],
  Akita: [
    "Akita",
    "Ōdate",
    "Kazuno",
    "Daisen",
    "Katagami",
    "Kitaakita",
    "Oga",
    "Yurihonjō",
    "Yuzawa",
    "Semboku",
    "Yokote",
    "Nikaho",
    "Noshiro",
  ],
  Aomori: [
    "Hachinohe",
    "Kuroishi",
    "Misawa",
    "Mutsu",
    "Towada",
    "Tsugaru",
    "Goshogawara",
    "Aomori",
    "Hirakawa",
    "Hirosaki",
  ],
  Chiba: [
    "Chiba",
    "Chōshi",
    "Ichikawa",
    "Funabashi",
    "Tateyama",
    "Kisarazu",
    "Matsudo",
    "Noda",
    "Mobara",
    "Narita",
    "Sakura",
    "Tōgane",
    "Narashino",
    "Kashiwa",
    "Katsuura",
    "Ichihara",
    "Nagareyama",
    "Yachiyo",
    "Abiko",
    "Kamagaya",
    "Kimitsu",
    "Futtsu",
    "Urayasu",
    "Yotsukaidō",
    "Sodegaura",
    "Yachimata",
    "Inzai",
    "Shiroi",
    "Tomisato",
    "Kamogawa",
    "Asahi",
    "Isumi",
    "Sōsa",
    "Minamibōsō",
    "Katori",
    "Sanmu",
    "Oamishirasato",
  ],
  Ehime: [
    "Matsuyama",
    "Niihama",
    "Shikokuchūō",
    "Seiyo",
    "Tōon",
    "Saijō",
    "Ōzu",
    "Imabari",
    "Yawatahama",
    "Iyo",
    "Uwajima",
  ],
  Fukui: [
    "Fukui",
    "Tsuruga",
    "Obama",
    "Ōno",
    "Katsuyama",
    "Sabae",
    "Awara",
    "Echizen",
    "Sakai",
  ],
  Fukuoka: [
    "Fukuoka",
    "Kurume",
    "Ōmuta",
    "Nōgata",
    "Tagawa",
    "Yanagawa",
    "Yame",
    "Chikugo",
    "Ōkawa",
    "Yukuhashi",
    "Buzen",
    "Nakagawa",
    "Nakama",
    "Kitakyushu",
    "Ogōri",
    "Chikushino",
    "Kasuga",
    "Ōnojō",
    "Munakata",
    "Dazaifu",
    "Koga",
    "Fukutsu",
    "Ukiha",
    "Miyawaka",
    "Asakura",
    "Iizuka",
    "Kama",
    "Miyama",
    "Itoshima",
  ],
  Fukushima: [
    "Aizuwakamatsu",
    "Fukushima",
    "Kōriyama",
    "Sukagawa",
    "Sōma",
    "Iwaki",
    "Tamura",
    "Shirakawa",
    "Nihonmatsu",
    "Minamisōma",
    "Date",
    "Kitakata",
    "Motomiya",
  ],
  Gifu: [
    "Gifu",
    "Ōgaki",
    "Takayama",
    "Tajimi",
    "Seki",
    "Nakatsugawa",
    "Mino",
    "Mizunami",
    "Hashima",
    "Minokamo",
    "Toki",
    "Kakamigahara",
    "Kani",
    "Yamagata",
    "Mizuho",
    "Hida",
    "Motosu",
    "Gujō",
    "Gero",
    "Ena",
    "Kaizu",
  ],
  Gunma: [
    "Maebashi",
    "Takasaki",
    "Kiryū",
    "Isesaki",
    "Ōta",
    "Numata",
    "Tatebayashi",
    "Fujioka",
    "Shibukawa",
    "Annaka",
    "Tomioka",
    "Midori",
  ],
  Hiroshima: [
    "Hiroshima",
    "Onomichi",
    "Kure",
    "Fukuyama",
    "Mihara",
    "Fuchū",
    "Miyoshi",
    "Shōbara",
    "Ōtake",
    "Takehara",
    "Higashihiroshima",
    "Hatsukaichi",
    "Akitakata",
    "Etajima",
  ],
  Hokkaidō: [
    "Sapporo",
    "Hakodate",
    "Otaru",
    "Asahikawa",
    "Muroran",
    "Obihiro",
    "Yūbari",
    "Iwamizawa",
    "Abashiri",
    "Rumoi",
    "Tomakomai",
    "Wakkanai",
    "Bibai",
    "Ashibetsu",
    "Ebetsu",
    "Akabira",
    "Monbetsu",
    "Mikasa",
    "Nemuro",
    "Chitose",
    "Takikawa",
    "Sunagawa",
    "Utashinai",
    "Fukagawa",
    "Furano",
    "Noboribetsu",
    "Eniwa",
    "Date",
    "Kitahiroshima",
    "Ishikari",
    "Shibetsu",
    "Kushiro",
    "Hokuto",
    "Kitami",
    "Nayoro",
  ],
  Hyōgo: [
    "Kobe",
    "Himeji",
    "Amagasaki",
    "Akashi",
    "Nishinomiya",
    "Ashiya",
    "Itami",
    "Aioi",
    "Toyooka",
    "Kakogawa",
    "Akō",
    "Takarazuka",
    "Miki",
    "Takasago",
    "Kawanishi",
    "Ono",
    "Sanda",
    "Kasai",
    "Sasayama",
    "Yabu",
    "Tamba",
    "Minamiawaji",
    "Asago",
    "Awaji",
    "Shisō",
    "Nishiwaki",
    "Tatsuno",
    "Sumoto",
    "Katō",
  ],
  Ibaraki: [
    "Mito",
    "Hitachi",
    "Tsuchiura",
    "Yūki",
    "Ryūgasaki",
    "Shimotsuma",
    "Jōsō",
    "Hitachiōta",
    "Takahagi",
    "Kitaibaraki",
    "Toride",
    "Ushiku",
    "Tsukuba",
    "Hitachinaka",
    "Kashima",
    "Itako",
    "Moriya",
    "Hitachiōmiya",
    "Naka",
    "Bandō",
    "Inashiki",
    "Chikusei",
    "Kasumigaura",
    "Kamisu",
    "Namegata",
    "Koga",
    "Ishioka",
    "Sakuragawa",
    "Hokota",
    "Kasama",
    "Tsukubamirai",
    "Omitama",
  ],
  Ishikawa: [
    "Kanazawa",
    "Nonoichi",
    "Nanao",
    "Komatsu",
    "Suzu",
    "Hakui",
    "Kahoku",
    "Hakusan",
    "Nomi",
    "Kaga",
    "Wajima",
  ],
  Iwate: [
    "Morioka",
    "Kamaishi",
    "Ōfunato",
    "Rikuzentakata",
    "Kitakami",
    "Miyako",
    "Hachimantai",
    "Ichinoseki",
    "Tōno",
    "Hanamaki",
    "Ninohe",
    "Ōshū",
    "Kuji",
    "Takizawa",
  ],
  Kagawa: [
    "Takamatsu",
    "Marugame",
    "Sakaide",
    "Zentsūji",
    "Sanuki",
    "Higashikagawa",
    "Kan'onji",
    "Mitoyo",
  ],
  Kagoshima: [
    "Kagoshima",
    "Kanoya",
    "Makurazaki",
    "Akune",
    "Izumi",
    "Ibusuki",
    "Minamisatsuma",
    "Nishinoomote",
    "Tarumizu",
    "Satsumasendai",
    "Hioki",
    "Soo",
    "Ichikikushikino",
    "Kirishima",
    "Shibushi",
    "Amami",
    "Minamikyūshū",
    "Isa",
    "Aira",
  ],
  Kanagawa: [
    "Yokohama",
    "Yokosuka",
    "Kawasaki",
    "Hiratsuka",
    "Kamakura",
    "Fujisawa",
    "Odawara",
    "Chigasaki",
    "Zushi",
    "Sagamihara",
    "Miura",
    "Hadano",
    "Atsugi",
    "Yamato",
    "Isehara",
    "Ebina",
    "Zama",
    "Minamiashigara",
    "Ayase",
  ],
  Kōchi: [
    "Kōchi",
    "Sukumo",
    "Aki",
    "Tosashimizu",
    "Susaki",
    "Tosa",
    "Muroto",
    "Nankoku",
    "Shimanto",
    "Kami",
    "Kōnan",
  ],
  Kumamoto: [
    "Kumamoto",
    "Yatsushiro",
    "Hitoyoshi",
    "Arao",
    "Minamata",
    "Tamana",
    "Yamaga",
    "Kikuchi",
    "Uto",
    "Kami-Amakusa",
    "Uki",
    "Aso",
    "Kōshi",
    "Amakusa",
  ],
  Kyoto: [
    "Kyoto",
    "Fukuchiyama",
    "Maizuru",
    "Ayabe",
    "Uji",
    "Miyazu",
    "Kameoka",
    "Jōyō",
    "Mukō",
    "Nagaokakyō",
    "Yawata",
    "Kyōtanabe",
    "Kyōtango",
    "Nantan",
    "Kizugawa",
  ],
  Mie: [
    "Yokkaichi",
    "Matsusaka",
    "Kuwana",
    "Suzuka",
    "Nabari",
    "Owase",
    "Kameyama",
    "Toba",
    "Inabe",
    "Shima",
    "Iga",
    "Ise",
    "Kumano",
    "Tsu",
  ],
  Miyagi: [
    "Sendai",
    "Ishinomaki",
    "Shiogama",
    "Shiroishi",
    "Natori",
    "Kakuda",
    "Tagajō",
    "Iwanuma",
    "Tome",
    "Kurihara",
    "Higashimatsushima",
    "Kesennuma",
    "Ōsaki",
    "Tomiya",
  ],
  Miyazaki: [
    "Miyazaki",
    "Miyakonojō",
    "Nobeoka",
    "Nichinan",
    "Kobayashi",
    "Hyūga",
    "Kushima",
    "Saito",
    "Ebino",
  ],
  Nagano: [
    "Matsumoto",
    "Okaya",
    "Suwa",
    "Suzaka",
    "Komoro",
    "Komagane",
    "Ōmachi",
    "Iiyama",
    "Iida",
    "Chino",
    "Shiojiri",
    "Nagano",
    "Chikuma",
    "Tōmi",
    "Nakano",
    "Saku",
    "Azumino",
    "Ueda",
    "Ina",
  ],
  Nagasaki: [
    "Nagasaki",
    "Sasebo",
    "Shimabara",
    "Ōmura",
    "Tsushima",
    "Iki",
    "Gotō",
    "Isahaya",
    "Saikai",
    "Hirado",
    "Unzen",
    "Matsuura",
    "Minamishimabara",
  ],
  Nara: [
    "Nara",
    "Yamatotakada",
    "Yamatokōriyama",
    "Tenri",
    "Kashihara",
    "Sakurai",
    "Gojō",
    "Gose",
    "Ikoma",
    "Kashiba",
    "Katsuragi",
    "Uda",
  ],
  Niigata: [
    "Niigata",
    "Nagaoka",
    "Kashiwazaki",
    "Shibata",
    "Ojiya",
    "Kamo",
    "Mitsuke",
    "Murakami",
    "Myōkō",
    "Jōetsu",
    "Sado",
    "Agano",
    "Uonuma",
    "Minami-Uonuma",
    "Itoigawa",
    "Tōkamachi",
    "Sanjō",
    "Tainai",
    "Gosen",
    "Tsubame",
  ],
  Ōita: [
    "Ōita",
    "Beppu",
    "Nakatsu",
    "Hita",
    "Saiki",
    "Usuki",
    "Tsukumi",
    "Taketa",
    "Bungotakada",
    "Usa",
    "Bungo-ōno",
    "Kitsuki",
    "Yufu",
    "Kunisaki",
  ],
  Okayama: [
    "Okayama",
    "Kurashiki",
    "Tsuyama",
    "Tamano",
    "Kasaoka",
    "Ibara",
    "Sōja",
    "Takahashi",
    "Niimi",
    "Bizen",
    "Setouchi",
    "Akaiwa",
    "Maniwa",
    "Mimasaka",
    "Asakuchi",
  ],
  Okinawa: [
    "Naha",
    "Ishigaki",
    "Ginowan",
    "Urasoe",
    "Nago",
    "Itoman",
    "Okinawa",
    "Tomigusuku",
    "Uruma",
    "Miyakojima",
    "Nanjō",
  ],
  Osaka: [
    "Osaka",
    "Sakai",
    "Kishiwada",
    "Toyonaka",
    "Ikeda",
    "Suita",
    "Izumiōtsu",
    "Takatsuki",
    "Kaizuka",
    "Moriguchi",
    "Hirakata",
    "Ibaraki",
    "Yao",
    "Izumisano",
    "Tondabayashi",
    "Neyagawa",
    "Kawachinagano",
    "Matsubara",
    "Daitō",
    "Izumi",
    "Minoh",
    "Kashiwara",
    "Habikino",
    "Kadoma",
    "Settsu",
    "Takaishi",
    "Fujiidera",
    "Higashiōsaka",
    "Sennan",
    "Shijōnawate",
    "Katano",
    "Ōsakasayama",
    "Hannan",
  ],
  Saga: [
    "Tosu",
    "Imari",
    "Kashima",
    "Taku",
    "Karatsu",
    "Ogi",
    "Saga",
    "Ureshino",
    "Takeo",
    "Kanzaki",
  ],
  Saitama: [
    "Kawagoe",
    "Kawaguchi",
    "Gyōda",
    "Chichibu",
    "Tokorozawa",
    "Hannō",
    "Kazo",
    "Higashimatsuyama",
    "Sayama",
    "Hanyū",
    "Kōnosu",
    "Ageo",
    "Sōka",
    "Koshigaya",
    "Warabi",
    "Toda",
    "Iruma",
    "Asaka",
    "Shiki",
    "Wakō",
    "Niiza",
    "Okegawa",
    "Kuki",
    "Kitamoto",
    "Yashio",
    "Fujimi",
    "Misato",
    "Hasuda",
    "Sakado",
    "Satte",
    "Tsurugashima",
    "Hidaka",
    "Yoshikawa",
    "Saitama",
    "Kumagaya",
    "Kasukabe",
    "Fujimino",
    "Fukaya",
    "Honjō",
    "Shiraoka",
  ],
  Shiga: [
    "Ōtsu",
    "Hikone",
    "Kusatsu",
    "Moriyama",
    "Rittō",
    "Kōka",
    "Yasu",
    "Konan",
    "Takashima",
    "Higashiōmi",
    "Maibara",
    "Nagahama",
    "Ōmihachiman",
  ],
  Shimane: [
    "Matsue",
    "Izumo",
    "Masuda",
    "Yasugi",
    "Gōtsu",
    "Unnan",
    "Hamada",
    "Ōda",
  ],
  Shizuoka: [
    "Shizuoka",
    "Hamamatsu",
    "Numazu",
    "Atami",
    "Mishima",
    "Fujinomiya",
    "Itō",
    "Shimada",
    "Iwata",
    "Yaizu",
    "Kakegawa",
    "Fujieda",
    "Gotenba",
    "Fukuroi",
    "Fuji",
    "Shimoda",
    "Susono",
    "Kosai",
    "Izu",
    "Omaezaki",
    "Kikugawa",
    "Izunokuni",
    "Makinohara",
  ],
  Tochigi: [
    "Utsunomiya",
    "Ashikaga",
    "Tochigi",
    "Kanuma",
    "Oyama",
    "Mooka",
    "Ōtawara",
    "Yaita",
    "Nasushiobara",
    "Sano",
    "Sakura",
    "Nasukarasuyama",
    "Shimotsuke",
    "Nikkō",
  ],
  Tokushima: [
    "Tokushima",
    "Naruto",
    "Komatsushima",
    "Anan",
    "Yoshinogawa",
    "Mima",
    "Awa",
    "Miyoshi",
  ],
  Tokyo: [
    "Hachiōji",
    "Tachikawa",
    "Musashino",
    "Mitaka",
    "Ōme",
    "Fuchū",
    "Akishima",
    "Chōfu",
    "Machida",
    "Koganei",
    "Kodaira",
    "Hino",
    "Higashimurayama",
    "Kokubunji",
    "Kunitachi",
    "Fussa",
    "Komae",
    "Higashiyamato",
    "Kiyose",
    "Higashikurume",
    "Musashimurayama",
    "Tama",
    "Inagi",
    "Hamura",
    "Akiruno",
    "Nishitōkyō",
    "Special wards of Tokyo",
    "Adachi",
    "Arakawa",
    "Bunkyō",
    "Chiyoda",
    "Chūō",
    "Edogawa",
    "Itabashi",
    "Katsushika",
    "Kita",
    "Kōtō",
    "Meguro",
    "Minato",
    "Nakano",
    "Nerima",
    "Ōta",
    "Setagaya",
    "Shibuya",
    "Shinagawa",
    "Shinjuku",
    "Suginami",
    "Sumida",
    "Taitō",
    "Toshima",
  ],
  Tottori: ["Tottori", "Yonago", "Kurayoshi", "Sakaiminato"],
  Toyama: [
    "Toyama",
    "Uozu",
    "Himi",
    "Namerikawa",
    "Tonami",
    "Oyabe",
    "Nanto",
    "Takaoka",
    "Imizu",
    "Kurobe",
  ],
  Wakayama: [
    "Wakayama",
    "Kainan",
    "Tanabe",
    "Gobō",
    "Arida",
    "Shingū",
    "Kinokawa",
    "Hashimoto",
    "Iwade",
  ],
  Yamagata: [
    "Yamagata",
    "Yonezawa",
    "Shinjō",
    "Sagae",
    "Kaminoyama",
    "Murayama",
    "Nagai",
    "Tendō",
    "Higashine",
    "Obanazawa",
    "Nan'yō",
    "Tsuruoka",
    "Sakata",
  ],
  Yamaguchi: [
    "Ube",
    "Hōfu",
    "Kudamatsu",
    "Shūnan",
    "Hikari",
    "Shimonoseki",
    "Yanai",
    "Hagi",
    "Nagato",
    "San'yō-Onoda",
    "Yamaguchi",
    "Iwakuni",
    "Mine",
  ],
  Yamanashi: [
    "Kōfu",
    "Fujiyoshida",
    "Tsuru",
    "Ōtsuki",
    "Nirasaki",
    "Minami-Alps",
    "Kai",
    "Fuefuki",
    "Hokuto",
    "Uenohara",
    "Yamanashi",
    "Kōshū",
    "Chūō",
  ],
};

export enum Menu {
  Profile = "profile",
  Favourites = "favourites",
  MyRecipe = "myrecipe",
  Friends = "friends",
  Album = "album",
  Post = "post",
}

export const DEFAULT_PET = {
  name: "",
  category: "",
  year: "",
  month: "",
  day: "",
  gender: "",
  avatar: "",
  fileName: "",
};
