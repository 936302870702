export const generateAddress = (area?: string, prefecture?: string) => {
  return area ? `${area}, ${prefecture}` : `${prefecture}`;
};

export const stringifyArray = (name: string, input: string[]) => {
  return input.reduce((result, current) => {
    result += `&${name}=${current}`;
    return result;
  }, "");
};

export const isBase64 = (input: string) =>
  input.includes("data:image/png;base64");
