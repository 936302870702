import { Layout, MyPagination, ProfileLayout, Section } from "components";
import { useProfileContext } from "components/ProfileLayout";
import { useDataContext } from "providers";
import { useState } from "react";
import { useUser } from "reactfire";
import { ALL_PER_SECTION, Menu } from "utils/constants";

const MyRecipeInner = () => {
  const { id, email } = useProfileContext();
  const { data: user } = useUser();
  const { recipeList } = useDataContext();

  const [page, setPage] = useState(1);
  const handlePageChange = (_: any, value: number) => {
    setPage(value);
  };

  const recipeRawData = recipeList.filter((recipe) => recipe.authorId === id);
  const length = Math.ceil(recipeRawData.length / ALL_PER_SECTION);
  const recipeData = recipeRawData.slice(
    (page - 1) * ALL_PER_SECTION,
    page * ALL_PER_SECTION
  );

  return (
    <Section
      recipeList={recipeData}
      add={user && user.email === email ? true : false}
    >
      <MyPagination count={length} page={page} onChange={handlePageChange} />
    </Section>
  );
};

const MyRecipe = () => {
  return (
    <Layout title="My Recipes">
      <ProfileLayout path={Menu.MyRecipe}>
        <MyRecipeInner />
      </ProfileLayout>
    </Layout>
  );
};

export default MyRecipe;
