import { Layout } from "components";
import { useForm } from "react-hook-form";
import { sendPasswordResetEmail } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { useAuth } from "reactfire";
import routes from "utils/routes";

type FormData = {
  email: string;
};

export default function Reset() {
  const navigate = useNavigate();
  const auth = useAuth();

  const resetPassword = async ({ email }: FormData) => {
    await sendPasswordResetEmail(auth, email);
    navigate(routes.signin);
  };

  const { register, handleSubmit } = useForm<FormData>();
  const onSubmit = handleSubmit((data) => resetPassword(data));

  return (
    <Layout title="Reset Password">
      <div className="min-h-screen max-w-7xl mx-auto border-x-2 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <h2 className="mt-6 text-center text-4xl font-bold text-gray-600">
            Reset Your Password
          </h2>
        </div>

        <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
          <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
            <form className="space-y-6" onSubmit={onSubmit}>
              <div>
                <label
                  htmlFor="email"
                  className="block text-sm font-medium text-gray-700"
                >
                  Email address
                </label>
                <div className="mt-1">
                  <input
                    id="email"
                    type="email"
                    autoComplete="email"
                    required
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-green-600 focus:border-green-600 sm:text-sm"
                    {...register("email")}
                  />
                </div>
              </div>

              <div>
                <button
                  type="submit"
                  className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-green-700 hover:bg-green-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-600"
                >
                  Reset Password
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </Layout>
  );
}
