import { PopularUserList, Slider, Layout, Section } from "components";
import { useDataContext } from "providers";
import { useUser } from "reactfire";
import { HOME_PER_SECTION } from "utils/constants";

const Home = () => {
  const { data: user } = useUser();
  const { recipeList, userList, favouriteSet } = useDataContext();
  const currentUser = userList.find((i) => i.email === user?.email);

  const popularUserData = [
    "iwPvCOIJMz8oZyJxAzY6",
    "ZtmLrTZjLEKAYJOoRGDa",
    "7BWbvEL8bGCZp7tujMSs",
    "0Gb8RT0Mm3zumo9kVmzP",
    "I07cRA3LIb4OIFYJRxu2",
    "PE5yIiz22KmolpyaYO0p",
  ].map((id) => userList.find((user) => user.id === id));

  const popularRecipeData = Object.keys(favouriteSet)
    .sort((a, b) => favouriteSet[b] - favouriteSet[a])
    .slice(0, HOME_PER_SECTION)
    .map((id) => recipeList.find((recipe) => recipe.id === id));

  const followingRecipeData = recipeList
    .filter((recipe) => currentUser?.followings.includes(recipe.authorId))
    .slice(0, HOME_PER_SECTION);

  return (
    <Layout title="Home">
      <div className="max-w-7xl mx-auto border-x-2">
        <Slider />
        <Section
          title="新着レシピ"
          recipeList={recipeList.slice(0, HOME_PER_SECTION)}
          currentUser={currentUser}
        />
        <Section
          title="おすすめ"
          recipeList={[...recipeList] //without mutating the original array
            .sort((a, b) => b.steps.length - a.steps.length)
            .slice(0, HOME_PER_SECTION)}
          currentUser={currentUser}
          noAd
        />
        <PopularUserList userList={popularUserData} currentUser={currentUser} />
        {user && followingRecipeData.length ? (
          <Section
            title="フォロワーからの投稿"
            recipeList={followingRecipeData}
            currentUser={currentUser}
          />
        ) : null}
        <Section
          title="話題のレシピ"
          recipeList={popularRecipeData}
          currentUser={currentUser}
          noAd
        />
      </div>
    </Layout>
  );
};

export default Home;
