const firebaseConfig = {
  apiKey: "AIzaSyDWSq9Id62p8njHvv-gHG6Ceu7LD3xUfLg",
  authDomain: "wango-d67d0.firebaseapp.com",
  projectId: "wango-d67d0",
  storageBucket: "wango-d67d0.appspot.com",
  messagingSenderId: "516598027074",
  appId: "1:516598027074:web:711e20093fb90d80802edb",
  measurementId: "G-5H45GZR8FJ",
};

export default firebaseConfig;
