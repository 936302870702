import { Fragment, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { AGE_SET, DOG_CATEGORY, PURPOSE_SET } from "utils/constants";
import { stringifyArray } from "utils/misc";
import { useNavigate } from "react-router-dom";
import routes from "utils/routes";

interface FormData {
  category1: string;
  category2: string;
  age: string[];
  purpose: string[];
  [key: string]: string | string[];
}
interface Props {
  open: boolean;
  setOpen: (status: boolean) => void;
}

const Filter = ({ open, setOpen }: Props) => {
  const navigate = useNavigate();
  const [state, setState] = useState<FormData>({
    category1: "",
    category2: "",
    age: [],
    purpose: [],
  });

  const handleSelect = (name: string) => (e: any) => {
    setState({
      ...state,
      [name]: e.target.value,
    });
  };

  const handleCheckbox = (name: string) => (e: any) => {
    const { value } = e.target;
    const currentValue = state[name] as string[];
    const checked = currentValue.includes(value);
    setState({
      ...state,
      [name]: checked
        ? currentValue.filter((i) => i !== value)
        : [...currentValue, value],
    });
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    setOpen(false);
    const { category1, category2, age, purpose } = state;
    const filterString = `category1=${category1}&category2=${category2}${stringifyArray(
      "age",
      age as string[]
    )}${stringifyArray("purpose", purpose as string[])}`;
    navigate(`${routes.all}?${filterString}`);
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="fixed z-10 inset-0 overflow-y-auto"
        onClose={setOpen}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <form
              className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-4xl sm:w-full sm:p-6"
              onSubmit={handleSubmit}
            >
              <div className="mt-3 text-center sm:mt-5">
                <Dialog.Title
                  as="h3"
                  className="text-3xl leading-6 font-medium text-gray-900"
                >
                  FILTER
                </Dialog.Title>
                <div className="mt-2">
                  <div className="text-xl font-bold text-gray-900 my-16 border-t border-green-800 relative">
                    <h2 className="absolute -top-4 left-32 md:left-96 bg-white	px-3">
                      犬種
                    </h2>
                    <div className="my-6 w-full">
                      <select
                        id="category1"
                        className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-green-600 focus:border-green-600 sm:text-sm"
                        value={state.category1}
                        name="category1"
                        onChange={handleSelect("category1")}
                      >
                        <option value="" disabled>
                          1匹目
                        </option>
                        {DOG_CATEGORY.slice(1).map(({ value, label }) => (
                          <option key={value} value={value}>
                            {label}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="my-6 w-full">
                      <select
                        id="category2"
                        className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-green-600 focus:border-green-600 sm:text-sm"
                        value={state.category2}
                        name="category2"
                        onChange={handleSelect("category2")}
                      >
                        <option value="" disabled selected>
                          2匹目
                        </option>
                        {DOG_CATEGORY.slice(1).map(({ value, label }) => (
                          <option key={value} value={value}>
                            {label}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="text-xl font-bold text-gray-900 my-16 border-t border-green-800 relative">
                    <h2 className="absolute -top-4 left-32 md:left-96 bg-white	px-3">
                      年齢
                    </h2>
                    <div className="mt-6 grid grid-cols-1 md:grid-cols-5 gap-3">
                      {AGE_SET.map(({ label, value }) => (
                        <div
                          key={value}
                          className={`border border-green-700 rounded-lg ${
                            state.age.includes(value)
                              ? "bg-green-700 text-white"
                              : ""
                          }`}
                        >
                          <label
                            htmlFor={value}
                            className="flex justify-center cursor-pointer p-2 text-sm"
                          >
                            <input
                              type="checkbox"
                              value={value}
                              id={value}
                              className="hidden"
                              name="age"
                              onClick={handleCheckbox("age")}
                            />
                            {label}
                          </label>
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className="text-xl font-bold text-gray-900 my-16 border-t border-green-800 relative">
                    <h2 className="absolute -top-4 left-32 md:left-96 bg-white	px-3">
                      目的
                    </h2>
                    <div className="mt-6 grid grid-cols-1 md:grid-cols-5 gap-x-6 gap-y-3">
                      {PURPOSE_SET.map(({ label, value }) => (
                        <div
                          key={value}
                          className={`border border-green-700 rounded-lg ${
                            state.purpose.includes(value)
                              ? "bg-green-700 text-white"
                              : ""
                          }`}
                        >
                          <label
                            htmlFor={value}
                            className="flex justify-center cursor-pointer p-2 text-sm"
                          >
                            <input
                              type="checkbox"
                              value={value}
                              id={value}
                              className="hidden"
                              name="purpose"
                              onClick={handleCheckbox("purpose")}
                            />
                            {label}
                          </label>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex justify-center">
                <button
                  type="submit"
                  className="inline-flex justify-center w-1/2 md:w-1/3 rounded-md border border-transparent shadow-sm px-4 py-2 bg-green-700 text-base font-medium text-white hover:bg-green-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-600 sm:text-sm"
                  //   onClick={() => setOpen(false)}
                >
                  この条件で検索
                </button>
              </div>
            </form>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default Filter;
