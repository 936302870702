import { FC } from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import Header from "./Header";
import Footer from "./Footer";

interface Props {
  title: string;
}

const Layout: FC<Props> = ({ title, children }) => {
  return (
    <HelmetProvider>
      <Helmet>
        <title>{title} - Wango</title>
      </Helmet>
      <div className="min-h-screen flex flex-col">
        <Header />
        <div className="grow">{children}</div>
        <Footer />
      </div>
    </HelmetProvider>
  );
};

export default Layout;
