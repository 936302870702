import { useDataContext } from "providers";
import { createContext, FC, useContext } from "react";
import { Navigate, useParams } from "react-router-dom";
import { User } from "types/collection";
import { Menu } from "utils/constants";
import routes from "utils/routes";
import ProfileHeader from "./ProfileHeader";

export const ProfileContext = createContext<User>({} as User);
export const useProfileContext = () => {
  return useContext(ProfileContext);
};

interface Props {
  path: Menu;
}

const ProfileLayout: FC<Props> = ({ children, path }) => {
  const { userId } = useParams();
  const { userList } = useDataContext();
  const userData = userList.find((i) => i.id === userId);

  return userData ? (
    <div className="max-w-7xl mx-auto border-x-2">
      <ProfileHeader userData={userData} path={path} />
      <ProfileContext.Provider value={userData}>
        {children}
      </ProfileContext.Provider>
    </div>
  ) : (
    <Navigate replace to={routes.notFound} />
  );
};

export default ProfileLayout;
