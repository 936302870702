import { ref } from "firebase/storage";
import { useFirestore, useStorage, useStorageDownloadURL } from "reactfire";
import { User } from "types/collection";
import { Link } from "react-router-dom";
import { generateRoute } from "utils/routes";
import { ReactComponent as FollowIcon } from "static/follow.svg";
import { doc, updateDoc } from "firebase/firestore";
import { COLLECTION_SET } from "utils/constants";

interface AvatarProps extends User {
  currentUser?: User;
}

const Avatar = ({ avatar, currentUser, id, followers }: AvatarProps) => {
  const firestore = useFirestore();
  const storage = useStorage();
  const coverRef = ref(storage, avatar);
  const { data: avatarUrl } = useStorageDownloadURL(coverRef);

  const handleFollow = async (e: any) => {
    e.preventDefault();
    if (currentUser) {
      const userRef = doc(firestore, COLLECTION_SET.USERS, id);
      await updateDoc(userRef, {
        followers: followers.includes(currentUser.id)
          ? followers.filter((i) => i !== currentUser.id)
          : [...followers, currentUser.id],
      });
    }
  };

  return (
    <Link
      to={generateRoute(id, "profile")}
      className={currentUser ? "block relative" : ""}
    >
      <img src={avatarUrl} alt="Avatar" className="rounded-full w-full" />
      {currentUser && currentUser.id !== id ? (
        <FollowIcon
          onClick={handleFollow}
          className={`w-8 h-8 absolute bottom-4 left-12 md:left-16 ${
            followers.includes(currentUser.id)
              ? "fill-blue-600"
              : "fill-gray-500"
          }`}
        />
      ) : null}
    </Link>
  );
};

interface Props {
  userList: (User | undefined)[];
  currentUser?: User;
}

const PopularUserList = ({ userList, currentUser }: Props) => {
  return (
    <div className="bg-orange-50 p-12 my-12">
      <h2 className="text-2xl font-bold text-gray-900">人気ユーザー</h2>
      <div className="grid grid-cols-2 md:grid-cols-6 mt-6 gap-8 md:gap-x-12">
        {userList.map((user) =>
          user ? (
            <Avatar key={user.id} {...user} currentUser={currentUser} />
          ) : null
        )}
      </div>
    </div>
  );
};

export default PopularUserList;
