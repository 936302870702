import { doc, updateDoc } from "firebase/firestore";
import { ref } from "firebase/storage";
import { useDataContext } from "providers";
import { Link } from "react-router-dom";
import {
  useFirestore,
  useStorage,
  useStorageDownloadURL,
  useUser,
} from "reactfire";
import albumImage from "static/ico-album.png";
import favouriteImage from "static/ico-favorite.png";
import followedImage from "static/ico-followed.png";
import followingImage from "static/ico-following.png";
import friendsImage from "static/ico-friends.png";
import profileImage from "static/ico-profile.png";
import recipeImage from "static/ico-recipe.png";
import { Pet, User } from "types/collection";
import { COLLECTION_SET, DEFAULT_AVATAR, Menu } from "utils/constants";
import { generateAddress } from "utils/misc";
import { generateRoute } from "utils/routes";

const PetItem = ({ name, avatar }: Pet) => {
  const storage = useStorage();
  const avatarRef = ref(storage, avatar ? avatar : DEFAULT_AVATAR.PET);
  const { data: avatarUrl } = useStorageDownloadURL(avatarRef);
  return (
    <div className="mt-4 ml-4 md:mt-0 md:ml-0 flex items-end" key={name}>
      <div className="flex items-center gap-x-3">
        <img src={avatarUrl} alt={name} className="w-10 md:w-16 rounded-full" />
        <p className="font-bold">{name}</p>
      </div>
    </div>
  );
};

interface Props {
  userData: User;
  path: Menu;
}

const ProfileHeader = ({ userData, path }: Props) => {
  const {
    id,
    avatar,
    followers,
    followings,
    nickname,
    area,
    prefecture,
    email,
    pets,
  } = userData;
  const { userList } = useDataContext();
  const { data: user } = useUser();
  const currentUser = userList.find((i) => i.email === user?.email);

  const firestore = useFirestore();
  const storage = useStorage();

  const avatarRef = ref(storage, avatar);
  const { data: avatarUrl } = useStorageDownloadURL(avatarRef);
  const userRef = doc(firestore, COLLECTION_SET.USERS, id);

  const updateFollow = async (userId: string) => {
    await updateDoc(userRef, {
      followers: followers.includes(userId)
        ? followers.filter((i) => i !== userId)
        : [...followers, userId],
    });
  };

  const handleClick = (userId: string) => () => {
    updateFollow(userId);
  };

  const menuSet = [
    {
      image: profileImage,
      to: generateRoute(id, Menu.Profile),
    },
    {
      image: favouriteImage,
      to: generateRoute(id, Menu.Favourites),
    },
    {
      image: recipeImage,
      to: generateRoute(id, Menu.MyRecipe),
    },
    {
      image: friendsImage,
      to: generateRoute(id, Menu.Friends),
    },
    { image: albumImage, to: generateRoute(id, Menu.Album) },
  ];

  return (
    <>
      {/* Header */}
      <div className="flex flex-col md:flex-row py-16 px-16 justify-between">
        {/* User Avatar */}
        <div className="flex items-center gap-x-6">
          <img
            src={avatarUrl}
            alt={nickname}
            className="w-20 md:w-36 rounded-full"
          />
          <div className="shrink-0 flex flex-col gap-y-2">
            <h2 className="text-lg md:text-4xl font-bold text-gray-900">
              {nickname}
            </h2>
            <p className="text-gray-400">{generateAddress(area, prefecture)}</p>
            <div className="flex gap-x-4">
              <div className="flex gap-x-1">
                <img src={followingImage} alt="Demo" />
                <span className="text-green-800">{followings.length}</span>
              </div>
              <div className="flex gap-x-1">
                <img src={followedImage} alt="Demo" />
                <span className="text-green-800">{followers.length}</span>
              </div>
              {currentUser && email !== currentUser.email ? (
                <div className="flex gap-x-1">
                  <img src={followedImage} alt="Demo" />
                  <button
                    className="text-green-800"
                    onClick={handleClick(currentUser.id)}
                  >
                    {followers.includes(currentUser.id) ? "Unfollow" : "Follow"}
                  </button>
                </div>
              ) : null}
            </div>
          </div>
        </div>

        {/* Pet Avatar */}
        <div className="flex gap-x-8">
          {pets.length
            ? pets.map((pet) => {
                return <PetItem {...pet} key={pet.name} />;
              })
            : null}
        </div>
      </div>

      {/* Menu */}
      <div className="mt-2 px-16 grid grid-cols-1 gap-6 md:grid-cols-5 gap-x-6">
        {menuSet.map((menu, index) => (
          <Link
            key={index}
            to={menu.to}
            className={
              menu.to === generateRoute(id, path)
                ? ""
                : "opacity-70 hover:opacity-100"
            }
          >
            <img src={menu.image} alt="Newest" className="w-full" />
          </Link>
        ))}
      </div>
    </>
  );
};

export default ProfileHeader;
