import { Dialog, Transition } from "@headlessui/react";
import { PlusIcon } from "@heroicons/react/solid";
import { Ad, Layout, MyPagination, ProfileLayout } from "components";
import { useProfileContext } from "components/ProfileLayout";
import "cropperjs/dist/cropper.css";
import { doc, updateDoc } from "firebase/firestore";
import { ref, uploadString } from "firebase/storage";
import { Fragment, useState } from "react";
import Cropper from "react-cropper";
import { Link } from "react-router-dom";
import {
  useFirestore,
  useStorage,
  useStorageDownloadURL,
  useUser,
} from "reactfire";
import {
  ALL_PER_SECTION,
  COLLECTION_SET,
  IMAGE_TYPE,
  Menu,
  UPLOAD_TYPE,
} from "utils/constants";
import { generateAlbumRoute } from "utils/routes";

interface Props {
  userId: string;
  url: string;
}

const AlbumCard = ({ userId, url }: Props) => {
  const storage = useStorage();
  const coverRef = ref(storage, url);
  const { data: coverUrl } = useStorageDownloadURL(coverRef);

  return (
    <div key={url}>
      <Link to={generateAlbumRoute(userId, url.split("/")[1])}>
        <img src={coverUrl} alt="Album" className="w-full h-60 rounded-lg" />
      </Link>
    </div>
  );
};

const AlbumInner = () => {
  const { photos, id, email } = useProfileContext();
  const { data: user } = useUser();

  const [page, setPage] = useState(1);
  const handlePageChange = (_: any, value: number) => {
    setPage(value);
  };

  const length = Math.ceil(photos.length / ALL_PER_SECTION);
  const albumData = photos.slice(
    (page - 1) * ALL_PER_SECTION,
    page * ALL_PER_SECTION
  );

  const firestore = useFirestore();
  const storage = useStorage();

  const [modal, setModal] = useState(false);
  const [image, setImage] = useState("");
  const [fileName, setFilename] = useState("");
  const [cropper, setCropper] = useState<any>();
  const onChange = (e: any) => {
    e.preventDefault();
    let files;
    if (e.dataTransfer) {
      files = e.dataTransfer.files;
    } else if (e.target) {
      files = e.target.files;
    }
    const reader = new FileReader();
    reader.onload = () => {
      setImage(reader.result as any);
    };
    reader.readAsDataURL(files[0]);
    setFilename(files[0].name);
    setModal(true);
  };
  const handleUpload = async () => {
    if (typeof cropper !== "undefined") {
      const storageRef = ref(storage, `${IMAGE_TYPE.PHOTOS}/${fileName}`);
      const userRef = doc(firestore, COLLECTION_SET.USERS, id);
      await uploadString(
        storageRef,
        cropper.getCroppedCanvas().toDataURL(),
        UPLOAD_TYPE
      );
      await updateDoc(userRef, {
        photos: [...photos, `${IMAGE_TYPE.PHOTOS}/${fileName}`],
      });
    }
    setModal(false);
  };

  return (
    <>
      <div className="px-16 grid grid-cols-4 py-16">
        <div className="mb-10 md:mb-0 col-span-4 md:col-span-3">
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            {user && user.email === email ? (
              <form className="border-2 border-dotted">
                <input
                  type="file"
                  id="photo"
                  accept="image/*"
                  className="hidden"
                  onChange={onChange}
                />
                <label
                  htmlFor="photo"
                  className="flex justify-center items-center h-60 cursor-pointer"
                >
                  <PlusIcon
                    className="block h-8 w-8 text-gray-400"
                    aria-hidden="true"
                  />
                </label>
              </form>
            ) : null}
            {albumData.map((url) => (
              <AlbumCard key={url} userId={id} url={url} />
            ))}
          </div>
          <MyPagination
            count={length}
            page={page}
            onChange={handlePageChange}
          />
        </div>
        <div className="flex flex-col items-center md:items-end col-span-4 md:col-span-1">
          <Ad />
        </div>
      </div>
      <Transition appear show={modal} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-10 overflow-y-auto"
          onClose={() => setModal(false)}
        >
          <div className="min-h-screen px-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="inline-block w-full max-w-md p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                <Dialog.Title
                  as="h3"
                  className="flex justify-center text-xl mb-3 font-medium text-gray-900"
                >
                  Cut the image
                </Dialog.Title>
                <Cropper
                  style={{ height: 400, width: "100%" }}
                  zoomTo={0.5}
                  initialAspectRatio={1}
                  src={image}
                  viewMode={1}
                  minCropBoxHeight={10}
                  minCropBoxWidth={10}
                  background={false}
                  responsive={true}
                  autoCropArea={1}
                  checkOrientation={false}
                  onInitialized={(instance) => {
                    setCropper(instance);
                  }}
                  guides={true}
                />

                <div className="mt-4 flex justify-center">
                  <button
                    type="button"
                    className="inline-flex justify-center px-4 py-2 text-sm font-medium text-gray-100 bg-green-700 border border-transparent rounded-md hover:bg-green-800 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-green-600"
                    onClick={handleUpload}
                  >
                    このサイズにカットします
                  </button>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

const Album = () => {
  return (
    <Layout title="Album">
      <ProfileLayout path={Menu.Album}>
        <AlbumInner />
      </ProfileLayout>
    </Layout>
  );
};

export default Album;
